/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components

import EventCalendar from "examples/Calendar";

// Calendar application components

// Data
import calendarEventsData from "layouts/pages/Patient/appointment/calendar/data/calendarEventsData";

function Calendar() {
  return (
    <SuiBox pt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
          {useMemo(
            () => (
              <EventCalendar
                initialView="dayGridMonth"
                initialDate="2022-04-10"
                events={calendarEventsData}
                selectable
                editable
              />
            ),
            [calendarEventsData]
          )}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Calendar;
