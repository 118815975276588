/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from "react";

// Soft UI Dashboard PRO React components
import { Tab, Tabs, Box, Icon, Card } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React example components
import DoctorDashboardLayout from "examples/LayoutContainers/DoctorDashboardLayout";

import Footer from "examples/Footer";

import DataTable from "examples/Tables/DataTable";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components

// Data

import dataTableDatamedical from "layouts/pages/Doctor/Subscription/components/dataTableDataMedical";

import pharmacy1 from "assets/images/pharmacy1.jfif";
import pharmacy2 from "assets/images/pharmacy2.jfif";
import pharmacy3 from "assets/images/pharmacy3.jfif";
import pharmacy4 from "assets/images/pharmacy4.jfif";
import grabfood from "assets/images/grabfood.png";

import delivro from "assets/images/delivro.jpg";
import dataTableData from "layouts/pages/Doctor/Subscription/components/dataTableData";
import foodPanda from "assets/images/foodpanda.png";
import food1 from "assets/images/food1.jfif";

import medicine1 from "assets/images/medicine1.jfif";

// Overview page components

// Data

function Subscription() {
  const ACCOUNT_TABS = [
    {
      value: "Pharmacy",
      icon: (
        <Icon fontSize="medium" color="primary">
          travel_explore
        </Icon>
      ),
      component: (
        <SuiBox py={3}>
          <SuiBox mb={3}>
            <Card>
              <SuiBox pt={2} px={2}>
                <SuiBox mb={0.5}>
                  <SuiTypography variant="h5" color="gradient" fontWeight="medium">
                    Search Pharmacies for Medicines
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} xl={3}>
                    <DefaultProjectCard
                      image={medicine1}
                      label="Esomephrazole"
                      title="Carry Medical "
                      logo={pharmacy1}
                      price="10 RM"
                      description="Carry Medical Provide Fresh and Excellent Quality Drugs"
                      action={{
                        type: "internal",
                        route: "/pages/profile/profile-overview",
                        color: "primary",
                        label: "Buy Now",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <DefaultProjectCard
                      image={medicine1}
                      logo={pharmacy2}
                      label="Esomephrazole"
                      title="Pool Medical"
                      price="9 RM"
                      description="High Quality is Our Strenght we provide good"
                      action={{
                        type: "internal",
                        route: "/pages/profile/profile-overview",
                        color: "primary",
                        label: "Buy Now",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <DefaultProjectCard
                      image={medicine1}
                      logo={pharmacy3}
                      label="Esomephrazole"
                      title="OK Phramacy"
                      price="8 RM"
                      description="Different people have different taste, and various types of tablet."
                      action={{
                        type: "internal",
                        route: "/pages/profile/profile-overview",
                        color: "primary",
                        label: "Buy Now",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <DefaultProjectCard
                      image={medicine1}
                      logo={pharmacy4}
                      label="Esomephrazole"
                      title="Hamza Pharma"
                      price="11 RM"
                      description="Different people have different taste, and various types of music."
                      action={{
                        type: "internal",
                        route: "/pages/profile/profile-overview",
                        color: "primary",
                        label: "Buy Now",
                      }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
          </SuiBox>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiBox my={3}>
                <Card>
                  <SuiBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    paddingTop={3}
                    paddingLeft={3}
                  >
                    <SuiBox>
                      <SuiTypography variant="h5" fontWeight="medium">
                        6 Medicine returned for the keyword Esso
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                  <DataTable
                    table={dataTableDatamedical}
                    entriesPerPage={{
                      defaultValue: 7,
                      entries: [5, 7, 10, 15, 20, 25],
                    }}
                    canSearch
                  />
                </Card>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      ),
    },
    {
      value: "Market",
      icon: (
        <Icon fontSize="medium" color="primary">
          view_list
        </Icon>
      ),
      component: (
        <SuiBox py={3}>
          <SuiBox mb={3}>
            <Card>
              <SuiBox pt={2} px={2}>
                <SuiBox mb={0.5}>
                  <SuiTypography variant="h5" color="gradient" fontWeight="medium">
                    Search Marketplaces and order what you need
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} xl={3}>
                    <DefaultProjectCard
                      image={food1}
                      label="Healthy Diet"
                      title="Food Panda"
                      logo={delivro}
                      price="5 RM"
                      description="As Uber works through a huge amount of internal management turmoil."
                      action={{
                        type: "internal",
                        route: "/pages/profile/profile-overview",
                        color: "primary",
                        label: "Buy Now",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <DefaultProjectCard
                      image={food1}
                      logo={foodPanda}
                      label="Healthy Diet"
                      title="Grab Food"
                      price="10 RM"
                      description="Music is something that every person has his or ing that every person has his or "
                      action={{
                        type: "internal",
                        route: "/pages/profile/profile-overview",
                        color: "primary",
                        label: "Buy Now",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <DefaultProjectCard
                      image={food1}
                      logo={grabfood}
                      label="Healthy Diet"
                      title="deliveroo"
                      price="15 RM"
                      description="Different people have different taste, and various types of music."
                      action={{
                        type: "internal",
                        route: "/pages/profile/profile-overview",
                        color: "primary",
                        label: "Buy Now",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={3}>
                    <DefaultProjectCard
                      image={food1}
                      logo={foodPanda}
                      label="Healthy Diet"
                      title="minimalist"
                      price="20 RM"
                      description="Different people have different taste, and various types of music."
                      action={{
                        type: "internal",
                        route: "/pages/profile/profile-overview",
                        color: "primary",
                        label: "Buy Now",
                      }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
          </SuiBox>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiBox my={3}>
                <Card>
                  <SuiBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    paddingTop={3}
                    paddingLeft={3}
                  >
                    <SuiBox>
                      <SuiTypography variant="h5" fontWeight="medium">
                        Other results for healthy diet search
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                  <DataTable
                    table={dataTableData}
                    entriesPerPage={{
                      defaultValue: 7,
                      entries: [5, 7, 10, 15, 20, 25],
                    }}
                    canSearch
                  />
                </Card>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      ),
    },
  ];
  const [currentTab, setCurrentTab] = useState("Pharmacy");

  return (
    <DoctorDashboardLayout>
      <DashboardNavbar />
      <SuiBox py={4}>
        <SuiBox marginLeft="30%">
          <Tabs
            sx={{ width: 400 }}
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            color="primary"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                color="primary"
                disableRipple
                key={tab.value}
                label={tab.value}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
        </SuiBox>
        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </SuiBox>

      <Footer />
    </DoctorDashboardLayout>
  );
}

export default Subscription;
