/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Image
import adobeXD from "assets/images/foodpanda.png";
import atlassian from "assets/images/small-logos/logo-atlassian.svg";
import slack from "assets/images/small-logos/logo-slack.svg";
import spotify from "assets/images/small-logos/logo-spotify.svg";
import jira from "assets/images/small-logos/logo-jira.svg";

const complexReportsDoughnutChartDataHospitals = {
  images: [adobeXD, atlassian, slack, spotify, jira],
  labels: [
    "Klinik Lee Healthcare",
    "Klinik Bandar Baru Nilai",
    "Klinik Mediviron Giant Nilai",
    "KLINIK NILAI IMPIAN",
    "Klinik Mediviron",
  ],
  datasets: {
    label: "Promotions by Clinics",
    backgroundColors: ["primary", "info", "warning", "success", "dark"],
    data: [19, 4, 10, 21, 2],
  },
};

export default complexReportsDoughnutChartDataHospitals;
