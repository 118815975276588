/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
import doctor1 from "assets/images/doctor1.jpg";
import doctor2 from "assets/images/doctor2.jpg";
import doctor3 from "assets/images/doctor4.jpg";
import doctor4 from "assets/images/doctor5.jpg";

const salesTableData = [
  {
    name: [doctor1, "Dr Ahmad"],
    specility: "Neurologist",
    consultation_fee: "RM 100",
    discount: "10%",
  },
  {
    name: [doctor2, "Dr Azam Shah"],
    specility: "Aurthopedic MD",
    consultation_fee: "RM 130",
    discount: "20%",
  },
  {
    name: [doctor3, "Dr Hanif"],
    specility: "Primary Care MD",
    consultation_fee: "RM 200",
    discount: "20%",
  },
  {
    name: [doctor4, "Dr Affan"],
    specility: "Cardilogist",
    consultation_fee: "RM 400",
    discount: "40%",
  },
];

export default salesTableData;
