/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function ChangePassword() {
  return (
    <Card id="change-password">
      <SuiBox p={3}>
        <SuiTypography variant="h5">
          Medical and Surgical Specilities in Klinik Pakar Kesihatan USIM
        </SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item lg={4} xs={12}>
            <SuiTypography variant="body2" color="text">
              Klinik Pakar kesihatan USIM mula beroperasi pasa 1 April 2015. Klinik yang
              berkonsepkan wakaf ini menawarkan pelbagai perkhidmatan kepakaran perubatan,perubatan
              am, perubatan keluarga, perkhidmatan pergigian dan lain-lain lagi dengan caj yang
            </SuiTypography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SuiTypography variant="body2" color="text">
              Klinik Pakar kesihatan USIM mula beroperasi pasa 1 April 2015. Klinik yang
              berkonsepkan wakaf ini menawarkan pelbagai perkhidmatan kepakaran perubatan,perubatan
              am, perubatan keluarga, perkhidmatan pergigian dan lain-lain lagi dengan caj yang
            </SuiTypography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SuiTypography variant="body2" color="text">
              Klinik Pakar kesihatan USIM mula beroperasi pasa 1 April 2015. Klinik yang
              berkonsepkan wakaf ini menawarkan pelbagai perkhidmatan kepakaran perubatan,perubatan
              am, perubatan keluarga, perkhidmatan pergigian dan lain-lain lagi dengan caj yang
            </SuiTypography>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default ChangePassword;
