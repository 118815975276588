/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import FullBody from "../FullBody";

function Authentication() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiTypography variant="h5">Patient and Family Support</SuiTypography>
      </SuiBox>
      <Grid container spacing={3}>
        <Grid marginLeft={3} item lg={6}>
          <Card>
            <SuiTypography sx={{ margin: 2 }} variant="h5" color="Primary">
              Patient and Family Support
            </SuiTypography>
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}> Guest Services</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Service one</Typography>
                <Divider variant="middle" />
                <Typography>Service Two</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                  International Patient Services
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius
                  pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                  Language Disability Services
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas
                  eros, vitae egestas augue. Duis vel est augue.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography>Patient Relations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas
                  eros, vitae egestas augue. Duis vel est augue.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography>Spiritual, Religious and Chaplaincy Services for Adults</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas
                  eros, vitae egestas augue. Duis vel est augue.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography>What to Bring for your Hospital Stay</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas
                  eros, vitae egestas augue. Duis vel est augue.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Card>
          <SuiBox style={{ marginTop: 10, marginBottom: 10 }}>
            {" "}
            <FullBody
              style={{ margin: 3 }}
              title="Excellent Services"
              discreption="InPatient and outpatient high quality services"
            />
          </SuiBox>
          <SuiBox style={{ marginBottom: 20 }}>
            {" "}
            <FullBody
              style={{ margin: 3 }}
              title="Excellent Services"
              discreption="InPatient and outpatient high quality services"
            />
          </SuiBox>{" "}
        </Grid>

        <Grid item xs={12} sm={12} lg={5}>
          <SuiBox style={{ marginBottom: 10 }}>
            {" "}
            <FullBody
              style={{ margin: 3 }}
              title="Excellent Services"
              discreption="InPatient and outpatient high quality services"
            />
          </SuiBox>
          <SuiBox style={{ marginBottom: 10 }}>
            {" "}
            <FullBody
              style={{ margin: 3 }}
              title="Excellent Services"
              discreption="InPatient and outpatient high quality services"
            />
          </SuiBox>{" "}
          <SuiBox style={{ marginBottom: 10 }}>
            {" "}
            <FullBody
              style={{ margin: 3 }}
              title="Excellent Services"
              discreption="InPatient and outpatient high quality services"
            />
          </SuiBox>{" "}
          <SuiBox style={{ marginBottom: 20 }}>
            {" "}
            <FullBody
              title="Excellent Services"
              discreption="InPatient and outpatient high quality services"
            />
          </SuiBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Authentication;
