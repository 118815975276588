/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components

// ProductsList page components
import ProductCell from "layouts/pages/Doctor/Subscription/components/ProductCell";

// Images
import grabfood from "assets/images/grabfood.png";

import delivro from "assets/images/delivro.jpg";

import foodPanda from "assets/images/foodpanda.png";
import food1 from "assets/images/food1.jfif";

// Badges

const dataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "product",
      width: "40%",
      Cell: ({ value: [name, data] }) => (
        <ProductCell image={data.image} name={name} checked={data.checked} />
      ),
    },
    { Header: "category", accessor: "category" },
    {
      Header: "Service By",
      accessor: "service",
      width: "40%",
      Cell: ({ value: [name, data] }) => <ProductCell image={data.image} name={name} />,
    },
    { Header: "Discount", accessor: "quantity" },
    { Header: "Price", accessor: "price" },
    { Header: "ID", accessor: "sku" },
  ],

  rows: [
    {
      product: ["Healthy Diet", { image: food1, checked: true }],
      category: "Food",
      price: "10 RM",
      sku: 243598234,
      quantity: 0,
      service: ["Food panda", { image: foodPanda }],
    },
    {
      product: ["Healthy Diet", { image: food1, checked: true }],
      category: "Food",
      price: "9 RM",
      sku: 877712,
      quantity: 5,

      service: ["Grab Food", { image: grabfood }],
    },
    {
      product: ["Healthy Diet", { image: food1, checked: false }],
      category: "Food",
      price: "25 RM",
      sku: "0134729",
      quantity: 9,
      service: ["Delivroo", { image: delivro }],
    },
    {
      product: ["Healthy Diet", { image: food1, checked: false }],
      category: "Food",
      price: "15 RM",
      sku: 113213,
      quantity: 5,
      service: ["Food Panda", { image: foodPanda }],
    },
    {
      product: ["Healthy Diet", { image: food1, checked: false }],
      category: "Food",
      price: "25 RM",
      sku: 634729,
      quantity: 7,
      service: ["Food Panda", { image: foodPanda }],
    },
    {
      product: ["Healthy Diet", { image: food1, checked: false }],
      category: "Food",
      price: "20 RM",
      sku: 634729,
      quantity: 0,
      service: ["Food Panda", { image: foodPanda }],
    },
    // {
    //   product: ["Y-3 Yohji Yamamoto", { image: yohjiYamamoto, checked: true }],
    //   category: "Shoes",
    //   price: "$869",
    //   sku: 634729,
    //   quantity: 725,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Alexander McQueen", { image: mcqueenShirt, checked: true }],
    //   category: "Clothing",
    //   price: "$1,199",
    //   sku: "00121399",
    //   quantity: 51293,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Luin Floor Lamp", { image: yellowChair, checked: true }],
    //   category: "Furniture",
    //   price: "$1,900",
    //   sku: 434729,
    //   quantity: 34,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Heron Preston T-shirt", { image: heronTshirt, checked: false }],
    //   category: "Clothing",
    //   price: "$149",
    //   sku: 928341,
    //   quantity: 0,
    //   status: "out of stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Gray Living Chair", { image: livingChair, checked: true }],
    //   category: "Furniture",
    //   price: "$2,099",
    //   sku: 9912834,
    //   quantity: 32,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Derbyshire Orange Sofa", { image: orangeSofa, checked: false }],
    //   category: "Furniture",
    //   price: "$2,999",
    //   sku: 561151,
    //   quantity: 22,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Burberry Low-Tops", { image: burberry, checked: true }],
    //   category: "Shoes",
    //   price: "$869",
    //   sku: 634729,
    //   quantity: 725,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Dolce & Gabbana Skirt", { image: dgSkirt, checked: false }],
    //   category: "Designer",
    //   price: "$999",
    //   sku: "01827391",
    //   quantity: 0,
    //   status: "out of stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Undercover T-shirt", { image: undercover, checked: true }],
    //   category: "Shoes",
    //   price: "$869",
    //   sku: 63472,
    //   quantity: 725,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
  ],
};

export default dataTableData;
