/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from "react";
import Calendar from "react-calendar";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import "react-calendar/dist/Calendar.css";
import { Box, Tab, Tabs } from "@mui/material";
import SuiAvatar from "components/SuiAvatar";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { Link } from "react-router-dom";

import SuiTypography from "components/SuiTypography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import doctor1 from "assets/images/doctor-avatar.jpg";
import SuiButton from "components/SuiButton";

// Images
const ACCOUNT_TABS = [
  {
    value: "Video Visit",
    icon: <Icon fontSize="large">video_call</Icon>,
    component: (
      <>
        <SuiBox display="flex">
          <SuiTypography sx={{ marginRight: 2 }} variant="h4" color="text">
            Video Visit
          </SuiTypography>
          <Icon color="primary" fontSize="large">
            video_call
          </Icon>
        </SuiBox>

        <SuiBox sx={{ marginTop: 1, marginBottom: 2 }}>
          <SuiTypography variant="body2">
            Video visit let you see a doctor from your mobile device instead of in-person
          </SuiTypography>
          <SuiTypography variant="button" color="primary">
            Next Available: Tuesday, March 22
          </SuiTypography>
        </SuiBox>
      </>
    ),
  },
  {
    value: "Office Visit",
    icon: <Icon fontSize="large">add_location</Icon>,
    component: (
      <>
        <SuiBox display="flex">
          <SuiTypography sx={{ marginRight: 2 }} variant="h4" color="text">
            Office Visit
          </SuiTypography>
          <Icon color="primary" fontSize="large">
            add_location
          </Icon>
        </SuiBox>

        <SuiBox sx={{ marginTop: 1, marginBottom: 2 }}>
          <SuiTypography variant="body2">Nilai, Negeri Sembilan, Malaysia</SuiTypography>
          <SuiTypography variant="button" color="primary">
            Appointment Available next: Monday
          </SuiTypography>
        </SuiBox>
      </>
    ),
  },
];

function AppointmentDetails() {
  const [date, setDate] = useState(new Date());

  const onChange = (date2) => {
    setDate(date2);
  };
  const [currentTab, setCurrentTab] = useState("Video Visit");
  return (
    <SuiBox>
      <Grid container spacing={2}>
        <Grid mb={12} item lg={7}>
          <Card sx={{ paddingTop: 2, paddingLeft: 5 }}>
            <SuiTypography variant="h4" color="primary">
              Schedual an Appointment
            </SuiTypography>
            <Grid item lg={12} sm={12} xs={12}>
              {" "}
              <SuiTypography>Are you new to this doctor</SuiTypography>
            </Grid>
            <Grid item lg={6} sm={6}>
              <SuiBox style={{ marginLeft: 10 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel value="me" control={<Radio />} label="Yes" />
                    <FormControlLabel
                      value="dependent"
                      control={<Radio />}
                      label="No, I have visited before"
                    />
                  </RadioGroup>
                </FormControl>
              </SuiBox>
            </Grid>
            <SuiTypography variant="h4" color="primary">
              Type of Appointment ?
            </SuiTypography>
            <Tabs
              sx={{ width: 500, marginTop: 2 }}
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(e, value) => setCurrentTab(value)}
            >
              {ACCOUNT_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={tab.value}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>

            <Box sx={{ mb: 4 }} />

            {ACCOUNT_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Card>
        </Grid>

        <Grid item lg={5}>
          <Card sx={{ padding: 3 }}>
            <SuiBox display="flex">
              <SuiAvatar
                src={doctor1}
                alt="profile-image"
                variant="rounded"
                size="xxl"
                shadow="lg"
              />
              <SuiBox sx={{ marginLeft: 2 }}>
                {" "}
                <SuiTypography variant="h6">Prof. Dr. Muhammad Shamsir Bin Mohd Aris</SuiTypography>
                <SuiTypography variant="body2" color="text">
                  Obstetics and Gynaecology
                </SuiTypography>
                <SuiTypography variant="body2" color="text">
                  Visiting Consultant
                </SuiTypography>
                <SuiTypography variant="body2" color="primary">
                  Video Visit
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <SuiBox style={{ marginTop: 4 }}>
                <Calendar borderColor="red" onChange={onChange} value={date} />
              </SuiBox>

              {date.toString() === "Wed Apr 13 2022 00:00:00 GMT+0430 (Afghanistan Time)" ? (
                <SuiBox padding={2}>
                  <SuiTypography>Morning</SuiTypography>
                  <Link to="/makeappointment">
                    <SuiButton style={{ marginRight: 3 }} variant="contained" color="primary">
                      3:00
                    </SuiButton>
                    <SuiButton style={{ marginRight: 3 }} variant="contained" color="primary">
                      6:00
                    </SuiButton>
                    <SuiButton variant="contained" color="primary">
                      1:00
                    </SuiButton>
                  </Link>
                </SuiBox>
              ) : (
                ""
              )}
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default AppointmentDetails;
