/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const reportsDoughnutChartData = {
  labels: ["Cordiology", "Neurology", "Dermatology", "Ortology", "Artrulogoy"],
  datasets: {
    label: "Consumption",
    backgroundColors: ["primary", "secondary", "info", "success", "warning"],
    data: [28, 40, 32, 32, 20],
  },
};

export default reportsDoughnutChartData;
