/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Setting pages components

function Notifications() {
  return (
    <Card id="notifications">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Treatments</SuiTypography>
        </SuiBox>
        <SuiBox
          paddingLeft={6}
          paddingTop={4}
          style={{ display: "flex", justifyContent: "space-between" }}
          mb={3}
        >
          <SuiTypography variant="button" color="text">
            Birthing Care
          </SuiTypography>
          <SuiTypography variant="button" color="text">
            Heart Care
          </SuiTypography>
          <SuiTypography variant="button" color="text">
            Emergency
          </SuiTypography>
          <SuiTypography variant="button" color="text">
            Femily Medicine
          </SuiTypography>
          <SuiTypography variant="button" color="text">
            Cancer Care
          </SuiTypography>
          <SuiTypography paddingRight={6} variant="button" color="text">
            Orthopedics
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Notifications;
