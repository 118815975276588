import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import SuiButton from "components/SuiButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import doctor2 from "assets/images/doctor2.jpg";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Patient() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345, boxShadow: 2 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title="Shrimp and Chorizo Paella"
        subheader={
          <SuiTypography variant="caption" color="error">
            Emergency
          </SuiTypography>
        }
      />
      <CardMedia component="img" height="194" image={doctor2} alt="Paella dish" />
      <CardContent>
        <SuiBox
          display="flex"
          marginTop={2}
          boxShadow={3}
          padding={1.5}
          borderRadius={10}
          alignItems="center"
          justifyContent="space-between"
          alignContent="space-between"
        >
          <SuiBox display="flex" alignItems="center" flexDirection="column">
            <Typography variant="caption" fontWeight="bold">
              D.O.B
            </Typography>
            <Typography variant="caption"> 15 May 2022</Typography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" flexDirection="column">
            <Typography variant="button" fontSize={11} fontWeight="bold">
              Sex
            </Typography>
            <Typography variant="caption">Male</Typography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" flexDirection="column">
            <Typography variant="caption" fontWeight="bold">
              Weight
            </Typography>
            <Typography variant="caption">70 Kg</Typography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignContent="space-between"
          boxShadow={3}
          marginTop={1}
          padding={1.5}
          borderRadius={10}
          alignItems="center"
        >
          <SuiBox display="flex" alignItems="center" flexDirection="column">
            <Typography variant="caption" fontSize={12} fontFamily="roboto" fontWeight="bold">
              Height
            </Typography>
            <Typography variant="caption">172 cm</Typography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" flexDirection="column">
            <Typography variant="caption" fontWeight="bold">
              Last Appoinment
            </Typography>
            <Typography variant="caption">18 Aug 2022</Typography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" flexDirection="column">
            <Typography variant="caption" fontWeight="bold">
              Register Date
            </Typography>
            <Typography variant="caption">18 May 2022</Typography>
          </SuiBox>
        </SuiBox>
      </CardContent>
      <CardActions disableSpacing>
        <SuiTypography variant="caption">September 14, 2016</SuiTypography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <SuiBox display="flex" marginBottom={1}>
            <SuiBox
              style={{
                marginRight: 4,
                backgroundColor: "#FAFB9D",
                paddingRight: 13,
                paddingLeft: 13,
                padding: 3,
                borderRadius: 5,
              }}
            >
              <Typography color="orange" variant="caption">
                Asthma
              </Typography>
            </SuiBox>
            <SuiBox
              style={{
                marginRight: 4,
                backgroundColor: "lightblue",
                paddingRight: 9,
                paddingLeft: 9,
                padding: 3,
                borderRadius: 5,
              }}
            >
              <Typography color="blue" variant="caption">
                Hypotisis
              </Typography>
            </SuiBox>
            <SuiBox
              style={{
                backgroundColor: "lightblue",
                paddingRight: 9,
                paddingLeft: 9,
                padding: 3,
                borderRadius: 5,
              }}
            >
              <Typography color="blue" variant="caption">
                Hypotisis
              </Typography>
            </SuiBox>
          </SuiBox>
          <SuiBox justifyContent="space-between" display="flex">
            <SuiBox style={{ marginRight: 3 }}>
              <SuiButton style={{ fontSize: 9 }} variant="gradient" color="primary" size="small">
                Offer
              </SuiButton>
            </SuiBox>
            <SuiBox style={{ marginRight: 3 }}>
              <SuiButton style={{ fontSize: 9 }} variant="gradient" color="primary" size="small">
                OFFER
              </SuiButton>
            </SuiBox>
            <SuiBox>
              <SuiButton
                style={{ fontSize: 9 }}
                fontSize={11}
                variant="gradient"
                color="primary"
                size="small"
              >
                FAMILY
              </SuiButton>
            </SuiBox>
          </SuiBox>
          <SuiBox marginTop={1} justifyContent="space-between" display="flex">
            <SuiBox style={{ marginRight: 3 }}>
              <SuiButton
                style={{ fontSize: 9, paddingLeft: 15, paddingRight: 15 }}
                variant="gradient"
                color="primary"
                size="small"
              >
                (306)5550121
              </SuiButton>
            </SuiBox>
            <SuiBox style={{ marginRight: 3 }}>
              <SuiButton
                style={{ fontSize: 9, paddingLeft: 18, paddingRight: 15 }}
                variant="gradient"
                color="primary"
                size="small"
              >
                DOCUMENTS
              </SuiButton>
            </SuiBox>
            <SuiBox>
              <SuiButton style={{ fontSize: 9 }} variant="gradient" color="primary" size="small">
                CHAT
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </CardContent>
      </Collapse>
    </Card>
  );
}
