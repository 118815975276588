/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";

import doctor3 from "assets/images/doctor4.jpg";
import doctor1 from "assets/images/doctor-avatar.jpg";
import doctor2 from "assets/images/doctor2.jpg";

import doctor5 from "assets/images/doctor1.jpg";
import doctor4 from "assets/images/doctor5.jpg";
import SuiButton from "components/SuiButton";
// Settings page components

function BasicInfo() {
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">About Us</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={6}>
            <SuiTypography variant="body2" color="text">
              Klinik Pakar kesihatan USIM mula beroperasi pasa 1 April 2015. Klinik yang
              berkonsepkan wakaf ini menawarkan pelbagai perkhidmatan kepakaran perubatan,perubatan
              am, perubatan keluarga, perkhidmatan pergigian dan lain-lain lagi dengan caj yang
              berpatutan.untuk senarai penuh perkhidmatan yang disediakan,sila KLIK DI SINI.
            </SuiTypography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" lg={6} sm={6}>
            <SuiTypography variant="body2" color="text">
              Lot 193,194 Jalan Nilai Square 6, Bandar Baru Nilai, 71800 Nilai, Negeri Sembilan
              Malaysia
            </SuiTypography>
            <SuiTypography variant="body2" color="text">
              https://klinikpakar.usim.edu.my
            </SuiTypography>
            <SuiTypography variant="body2" color="text">
              +60126504921
            </SuiTypography>
            <SuiTypography variant="body2" color="text">
              RQFV+HC Nilai, Negeri Sembilan, Malaysia
            </SuiTypography>
          </Grid>

          <Grid item xs={12} lg={12}>
            <SuiBox marginBottom={3}>
              <SuiTypography variant="h5">Our Team</SuiTypography>
            </SuiBox>
            <Grid container spacing={3}>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor1}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} variant="body2" color="text">
                  PROF DR. Muhammad shamsir
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor2}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} variant="body2" color="text">
                  PROF DR. MADYA DR. Khairullah
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor5}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} marginBottom={3} variant="body2" color="text">
                  Dr. Mohd Radzniwan
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor3}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} marginBottom={3} variant="body2" color="text">
                  Dr. Fatima Begum
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor4}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} marginBottom={3} variant="body2" color="text">
                  Dr. Sharifah Najwa
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default BasicInfo;
