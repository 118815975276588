/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function NewCategoriesList({ title, categories, image, dropdown }) {
  const renderItems = categories.map(({ color, name, description, date }, key) => (
    <SuiBox
      key={name}
      component="li"
      display="flex"
      alignItems="center"
      borderRadius="lg"
      justifyContent="space-between"
      py={1}
      pr={3}
      mb={categories.length - 1 === key ? 0 : 1}
    >
      <SuiBox display="flex" alignItems="center">
        <SuiBox
          component="img"
          src={image}
          alt="message-image"
          borderRadius="50%"
          shadow="md"
          width="25%"
          marginRight={1}
          display="inherit"
        />
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="button" color={color} fontWeight="medium" gutterBottom>
            {name}
          </SuiTypography>
          <SuiTypography variant="caption" color="text">
            {description}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox display="flex" alignItems="center">
        <SuiBox>
          <SuiTypography fontSize={10} color={color}>
            {date}
          </SuiTypography>
        </SuiBox>
        <SuiBox>
          {dropdown && (
            <SuiTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                width: "16px",
                cursor: "pointer",
              }}
            >
              <Icon fontSize="default">more_vert</Icon>
            </SuiTypography>
          )}
          {dropdown.menu}
        </SuiBox>
      </SuiBox>
    </SuiBox>
  ));

  return (
    <Card>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderItems}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}
NewCategoriesList.defaultProps = {
  dropdown: false,
};
// Typechecking props for the CategoriesList
NewCategoriesList.propTypes = {
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.string.isRequired,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default NewCategoriesList;
