/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Sessions() {
  return (
    <Card id="sessions">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5"> For Our Patients</SuiTypography>
        </SuiBox>
      </SuiBox>
      <Grid container ml={1} mb={4} spacing={3}>
        <Grid item justifyContent="center" alignItems="center" textAlign="center" lg={6}>
          <Icon fontSize="large" color="primary">
            access_time
          </Icon>
          <SuiTypography variant="h5" color="text">
            Visiting Hours
          </SuiTypography>
          <SuiTypography variant="body2" color="text" fontWeight="strong">
            Guest are encouraged to visit loved one during their hospital stay.
          </SuiTypography>
        </Grid>
        <Grid item justifyContent="center" alignItems="center " textAlign="center" lg={6}>
          <Icon fontSize="large" color="primary">
            wifi
          </Icon>
          <SuiTypography variant="h5" color="text">
            For Our Patients
          </SuiTypography>
          <SuiTypography variant="body2" color="text" fontWeight="strong">
            Free Wifi is available to Patients and Visior
          </SuiTypography>
        </Grid>
        <SuiTypography style={{ marginTop: 8, marginLeft: 20 }} variant="button" color="primary">
          Learn More About Our Visiting Hours
        </SuiTypography>
      </Grid>
    </Card>
  );
}

export default Sessions;
