/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import ReactApexChart from "react-apexcharts";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import NewCategoriesList from "examples/Lists/NewCategoriesList";

import SalesTable from "examples/Tables/SalesTable";

import ComplexReportsDoughnutChart from "examples/Charts/DoughnutCharts/ComplexReportsDoughnutChart";

import Chart from "layouts/pages/Patient/default/componentss/Chart";
import incomeChartData from "layouts/pages/Data/incomeChartData";

import complexReportsDoughnutChartDataMarketplaces from "layouts/pages/Data/complexReportsDoughnutChartDataMarketplaces";
import complexReportsDoughnutChartDataPharmacies from "layouts/pages/Data/complexReportsDoughnutChartDataPharmacies";
import complexReportsDoughnutChartDataHospitals from "layouts/pages/Data/complexReportsDoughnutChartDataHospitals";
// Soft UI Dashboard PRO React base styles
import CategoriesList from "examples/Lists/CategoriesList";
import breakpoints from "assets/theme/base/breakpoints";
// Data
import categoriesListData from "layouts/pages/Data/categoriesListData";
import salesTableData from "layouts/pages/Data/salesTableData";
import kalVisualsSquare from "assets/images/doctor1.jpg";

function Default() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCmkgmny08KLFazm8YI30a7MwtrN_qLwi0",
  });
  const [marketingMenu, setMarketingMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const openMarketingMenu = (event) => setMarketingMenu(event.currentTarget);
  const closeMarketingMenu = () => setMarketingMenu(null);

  const renderMarketingMenu = (
    <Menu
      anchorEl={marketingMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(marketingMenu)}
      onClose={closeMarketingMenu}
      keepMounted
    >
      <MenuItem onClick={closeMarketingMenu}>Action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Something else here</MenuItem>
    </Menu>
  );
  const { values } = breakpoints;

  const center = {
    lat: 3.139,
    lng: 101.6879,
  };
  const marker8 = {
    lat: 3.199,
    lng: 101.4969,
  };
  const marker7 = {
    lat: 3.938,
    lng: 101.4869,
  };
  const marker6 = {
    lat: 3.339,
    lng: 101.5969,
  };
  const marker5 = {
    lat: 3.149,
    lng: 101.697,
  };
  const marker4 = {
    lat: 3.149,
    lng: 101.5969,
  };
  const marker3 = {
    lat: 3.138,
    lng: 101.6869,
  };
  const marker2 = {
    lat: 3.239,
    lng: 101.4969,
  };
  const marker1 = {
    lat: 3.139,
    lng: 101.497,
  };
  const containerStyle = {
    width: "100%",
    height: "680px",
  };
  // radar chart data
  const radar = {
    series: [
      {
        name: "Malaria",
        data: [80, 50, 30, 40, 100, 20],
      },
      {
        name: "Cold",
        data: [20, 30, 40, 80, 20, 80],
      },
      {
        name: "Typhoid",
        data: [44, 76, 78, 13, 43, 10],
      },
      {
        name: "Cough",
        data: [80, 20, 78, 39, 54, 14],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      title: {
        text: "Chronic wellness Tracker",
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: ["2017", "2018", "2019", "2020", "2021", "2022"],
      },
    },
  };
  // stacked chart data
  const state = {
    series: [
      {
        name: "Emergency",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "Eximination",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "Consultation",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "Routine Checkup",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      title: {
        text: "Appointment",
      },
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "jan",
          "Feb.",
          "Mar.",
          "Apr.",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Nov",
          "Dec",
        ],
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoaded ? (
        <SuiBox py={3}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <SuiBox mb={3} p={1}>
                <SuiTypography
                  variant={window.innerWidth < values.sm ? "h5" : "h4"}
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  Welcome to MyPatietnHUB!
                </SuiTypography>
              </SuiBox>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <ComplexReportsDoughnutChart
                    title="Promotion by Clinics"
                    chart={complexReportsDoughnutChartDataHospitals}
                    tooltip="See which clinics are providing you promos"
                    action={{
                      type: "internal",
                      route: "/",
                      color: "secondary",
                      label: "More Details",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ComplexReportsDoughnutChart
                    title="Promotion by Pharmacies"
                    chart={complexReportsDoughnutChartDataPharmacies}
                    tooltip="See which clinics are providing you promos"
                    action={{
                      type: "internal",
                      route: "/",
                      color: "secondary",
                      label: "More Details",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ComplexReportsDoughnutChart
                    title="Smart Market Usage by app"
                    chart={complexReportsDoughnutChartDataMarketplaces}
                    tooltip="See which websites are sending traffic to your website"
                    action={{
                      type: "internal",
                      route: "/",
                      color: "secondary",
                      label: "see all referrals",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Chart
                    title="Health Index"
                    count="70%"
                    percentage={{ color: "success", label: "+3%" }}
                    chart={incomeChartData}
                  />
                  <Chart
                    title="Symptoms"
                    count="70%"
                    percentage={{ color: "success", label: "+3%" }}
                    chart={incomeChartData}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SuiBox display="flex" justifyContent="space-between">
                    <Grid item xs={12} lg={12}>
                      <Card style={{ padding: 7 }}>
                        <ReactApexChart
                          options={radar.options}
                          series={radar.series}
                          type="radar"
                          height={350}
                        />
                      </Card>
                    </Grid>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SuiBox display="flex" justifyContent="space-between">
                    <Grid item xs={12} lg={12}>
                      <Card style={{ padding: 7 }}>
                        <ReactApexChart
                          options={state.options}
                          series={state.series}
                          type="bar"
                          height={350}
                        />
                      </Card>
                    </Grid>
                  </SuiBox>
                </Grid>
                <Grid item lg={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <Grid item xs={12} lg={12} style={{ marginTop: 10 }}>
                        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
                          {/* Child components, such as markers, info windows, etc. */}
                          <Marker position={marker1} />
                          <Marker position={marker2} />
                          <Marker position={marker3} />
                          <Marker position={marker4} />
                          <Marker position={marker5} />
                          <Marker position={marker6} />
                          <Marker position={marker7} />
                          <Marker position={marker8} />
                        </GoogleMap>
                      </Grid>
                    </Grid>
                    <Grid item lg={6}>
                      <SuiBox sx={{ marginTop: 2 }}>
                        <NewCategoriesList
                          image={kalVisualsSquare}
                          title="Previous Appoinments"
                          categories={categoriesListData}
                          dropdown={{
                            action: openMarketingMenu,
                            menu: renderMarketingMenu,
                          }}
                        />
                      </SuiBox>
                      <SuiBox sx={{ marginTop: 2 }}>
                        <CategoriesList
                          image={kalVisualsSquare}
                          title="Up Coming Appoinments"
                          categories={categoriesListData}
                          dropdown={{
                            action: openMarketingMenu,
                            menu: renderMarketingMenu,
                          }}
                        />
                      </SuiBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid marginTop={6} item xs={12} md={12} lg={12}>
              <Grid item xs={12} lg={12}>
                <SuiBox mb={3} position="relative">
                  <SalesTable title="Doctors" rows={salesTableData} />
                </SuiBox>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      ) : (
        <CircularProgress color="secondary" />
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
