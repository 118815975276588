/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components

// Soft UI Dashboard PRO React icons

// Soft UI Dashboard PRO React base styles

// Images

import curved0 from "assets/images/curved-images/curved0.jpg";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import patient from "assets/images/patient.png";
import hospital from "assets/images/hospital2.jpg";
import doctor from "assets/images/doctor.png";

function Header() {
  /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */

  // Remove event listener on cleanup

  return (
    <SuiBox position="relative">
      <SuiBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.primary.main, 0.6),
              rgba(gradients.primary.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <SuiBox
        sx={{
          position: "relative",
          mt: -30,
          mx: 3,
          py: 2,
        }}
      >
        <Grid container justifyContent="center" spacing={3}>
          <Grid textAlign="center" item sm={12} xs={12} lg={12}>
            <SuiTypography variant="h1" color="white">
              Register As Patient
            </SuiTypography>
          </Grid>
          <Grid display="flex" marginTop={2} justifyContent="space-between" lg={5} sm={12} xs={12}>
            <Link to="/patientregistration">
              <Card sx={{ border: 3, borderColor: "#54A8F2" }}>
                <SuiAvatar
                  src={patient}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="lg"
                />
                <SuiTypography textAlign="center" color="primary">
                  Patient
                </SuiTypography>
              </Card>
            </Link>
            <Link to="/doctorregistration">
              <Card>
                <SuiAvatar
                  src={doctor}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="lg"
                />
                <SuiTypography textAlign="center" color="primary">
                  Doctor
                </SuiTypography>
              </Card>
            </Link>

            <Link to="/hospitalregistration">
              <Card>
                <SuiAvatar
                  src={hospital}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="lg"
                />
                <SuiTypography textAlign="center" color="primary">
                  Hospital
                </SuiTypography>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Header;
