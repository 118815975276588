/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { useState } from "react";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import ReactApexChart from "react-apexcharts";
import Grid from "@mui/material/Grid";

import { Tab, Tabs, Box, Icon } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

import CategoriesList from "examples/Lists/CategoriesList";
import NewCategoriesList from "examples/Lists/NewCategoriesList";

// Data

import miniGradientLineChartData from "layouts/pages/Data/miniGradientLineChartData";
import categoriesListData from "layouts/pages/Data/categoriesListData";
import doctor2 from "assets/images/doctor2.jpg";
import Calendar from "./calendar";

function Appointment() {
  const [marketingMenu, setMarketingMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const openMarketingMenu = (event) => setMarketingMenu(event.currentTarget);
  const closeMarketingMenu = () => setMarketingMenu(null);

  const { visitorsChart } = miniGradientLineChartData;

  const renderMarketingMenu = (
    <Menu
      anchorEl={marketingMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(marketingMenu)}
      onClose={closeMarketingMenu}
      keepMounted
    >
      <MenuItem onClick={closeMarketingMenu}>Action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Something else here</MenuItem>
    </Menu>
  );
  const state = {
    series: [
      {
        name: "Emergency",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "Eximination",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "Consultation",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "Routine Checkup",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      title: {
        text: "Appointment",
      },
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [
          "jan",
          "Feb.",
          "Mar.",
          "Apr.",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Nov",
          "Dec",
        ],
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };
  const ACCOUNT_TABS = [
    {
      value: "Appointments",
      icon: (
        <Icon fontSize="medium" color="primary">
          travel_explore
        </Icon>
      ),
      component: (
        <SuiBox mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5} sm={4}>
              <MiniGradientLineChart
                title="Procedures Taken"
                description={
                  <SuiTypography variant="h5" fontWeight="bold">
                    30{" "}
                    <SuiTypography variant="button" color="success" fontWeight="bold">
                      +3
                    </SuiTypography>
                  </SuiTypography>
                }
                chart={visitorsChart}
              />
            </Grid>
            <Grid item xs={12} lg={5} sm={4}>
              <MiniGradientLineChart
                title="Treatments Taken"
                description={
                  <SuiTypography variant="h5" fontWeight="bold">
                    12{" "}
                    <SuiTypography variant="button" color="success" fontWeight="bold">
                      +1
                    </SuiTypography>
                  </SuiTypography>
                }
                chart={visitorsChart}
              />
            </Grid>
            <Grid item xs={12} lg={2} sm={4}>
              <Link to="/searchdoctor">
                <PlaceholderCard title={{ variant: "h6", text: "New Appointment" }} hasBorder />
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Grid container spacing={2}>
                <Grid sx={{ marginTop: -3 }} item xs={12} lg={8}>
                  <Calendar />
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                  <Grid item xs={12}>
                    <Card style={{ padding: 10, marginBottom: 10 }}>
                      <ReactApexChart
                        options={state.options}
                        series={state.series}
                        type="bar"
                        height={350}
                      />
                    </Card>
                    <SuiBox style={{ marginBottom: 10 }}>
                      <NewCategoriesList
                        image={doctor2}
                        title="Previous Appoinments"
                        categories={categoriesListData}
                        dropdown={{
                          action: openMarketingMenu,
                          menu: renderMarketingMenu,
                        }}
                      />
                    </SuiBox>
                  </Grid>
                  <Grid marginBottom={3} item xs={12}>
                    <CategoriesList
                      image={doctor2}
                      title="Up Coming Appoinments"
                      categories={categoriesListData}
                      dropdown={{
                        action: openMarketingMenu,
                        menu: renderMarketingMenu,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      ),
    },
    {
      value: "New Booking",
      icon: (
        <Icon fontSize="medium" color="primary">
          view_list
        </Icon>
      ),
      component: (
        <Grid lg={12}>
          <SuiBox style={{ marginLeft: 350, marginTop: 15, marginBottom: "1%" }}>
            <SuiButton variant="gradient" color="primary">
              BOOK AN APPOINTMENT WITH DOCTOR
            </SuiButton>
          </SuiBox>
          <SuiBox style={{ marginLeft: 390, marginBottom: "30%" }}>
            <Link to="/findhospital">
              <SuiButton variant="gradient" color="primary">
                BOOK ROOM IN HOSPITAL
              </SuiButton>
            </Link>
          </SuiBox>
        </Grid>
      ),
    },
  ];
  const [currentTab, setCurrentTab] = useState("Appointments");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={1}>
        <SuiBox marginLeft="30%" marginBottom={2}>
          <Tabs
            sx={{ width: 400 }}
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            color="primary"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                color="primary"
                disableRipple
                key={tab.value}
                label={tab.value}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
        </SuiBox>
        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </SuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Appointment;
