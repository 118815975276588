/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import doctor2 from "assets/images/doctor2.jpg";
// Settings page components
import qr from "assets/images/qr.png";
import DoctorDashboardLayout from "examples/LayoutContainers/DoctorDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuiButton from "components/SuiButton";
import { useState } from "react";

// Soft UI Dashboard PRO React components
import { Tab, Tabs, Box, Icon, Card, Grid } from "@mui/material";

function DoctorReward() {
  const ACCOUNT_TABS = [
    {
      value: "Rewards",
      icon: (
        <Icon fontSize="medium" color="primary">
          travel_explore
        </Icon>
      ),
      component: (
        <Grid container spacing={1}>
          <Grid justifyContent="space-between" item lg={12} sx={12} xs={12}>
            <SuiBox display="flex" justifyContent="space-between" p={2}>
              <SuiTypography variant="h5">From Clinics</SuiTypography>
              <SuiButton variant="text" color="success">
                See More
              </SuiButton>
            </SuiBox>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  variant="gradient"
                  bgcolor="primary"
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% Salary Bounus
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% Salary Bounus
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% Salary Bounus
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid justifyContent="space-between" item lg={12} sx={12} xs={12}>
            <SuiBox display="flex" justifyContent="space-between" p={2}>
              <SuiTypography variant="h5">From Pharmacies</SuiTypography>
              <SuiButton variant="text" color="success">
                See More
              </SuiButton>
            </SuiBox>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% discount on at caring Pharmacy
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% discount on at caring Pharmacy
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% discount on at caring Pharmacy
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid justifyContent="space-between" item lg={12} sx={12} xs={12}>
            <SuiBox display="flex" justifyContent="space-between" p={2}>
              <SuiTypography variant="h5">From MarketPlace</SuiTypography>
              <SuiButton variant="text" color="success">
                See More
              </SuiButton>
            </SuiBox>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% discount at FoodPanda
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% discount at FoodPanda
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          10% discount at FoodPanda
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid justifyContent="space-between" item lg={12} sx={12} xs={12}>
            <SuiBox display="flex" justifyContent="space-between" p={2}>
              <SuiTypography variant="h5">From International Rewards</SuiTypography>
              <SuiButton variant="text" color="success">
                See More
              </SuiButton>
            </SuiBox>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          Certified by WHO
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          Certified by WHO
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item lg={4}>
            <Card id="sessions">
              <SuiBox p={1} paddingLeft={2}>
                <SuiBox
                  component="li"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  borderRadius="lg"
                  py={1}
                  pr={2}
                >
                  <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <SuiBox>
                      <SuiBox
                        component="img"
                        src={doctor2}
                        alt="message-image"
                        borderRadius="50%"
                        shadow="md"
                        width="40%"
                        marginRight={1}
                      />
                      <SuiBox display="flex" flexDirection="column">
                        <SuiTypography variant="caption" gutterBottom>
                          Promo Code
                        </SuiTypography>
                        <SuiTypography variant="caption" gutterBottom fontWeight="bold">
                          Certified by WHO
                        </SuiTypography>
                        <SuiBox display="flex" marginTop={1} flexDirection="column">
                          <SuiTypography variant="caption" gutterBottom>
                            Discount
                          </SuiTypography>
                          <SuiTypography variant="button" fontWeight="bold" gutterBottom>
                            30%
                          </SuiTypography>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                    <SuiBox
                      marginLeft={8}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      alignContent="space-between"
                      flexDirection="column"
                    >
                      <SuiBox
                        component="img"
                        src={qr}
                        alt="message-image"
                        borderRadius="10%"
                        shadow="md"
                        width="70%"
                        display="inherit"
                      />
                      <SuiTypography marginTop={1} variant="button">
                        Expiration Date
                      </SuiTypography>
                      <SuiTypography variant="button">21-05-2022</SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      ),
    },
    {
      value: "Add Reward",
      icon: (
        <Icon fontSize="medium" color="primary">
          view_list
        </Icon>
      ),
      component: (
        <SuiBox
          alignItems="center"
          style={{
            marginLeft: 350,
            marginTop: 100,
            marginBottom: "30%",
            alignItems: "center",
            justifyContent: "center",
            alignText: "center",
          }}
        >
          <SuiTypography alignItems="center" variant="h4" fontWeight="bold">
            No Discount?
          </SuiTypography>
          <SuiTypography alignText="center">Your Statment Will be here</SuiTypography>
          <SuiButton variant="gradient" color="primary">
            ADD REWARD CODE
          </SuiButton>
        </SuiBox>
      ),
    },
  ];
  const [currentTab, setCurrentTab] = useState("Appointments");

  return (
    <DoctorDashboardLayout>
      <DashboardNavbar />
      <SuiBox py={4}>
        <SuiBox marginLeft="30%">
          <Tabs
            sx={{ width: 400 }}
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            color="primary"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                color="primary"
                disableRipple
                key={tab.value}
                label={tab.value}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
        </SuiBox>
        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </SuiBox>

      <Footer />
    </DoctorDashboardLayout>
  );
}

export default DoctorReward;
