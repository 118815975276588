/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";

function ChangePassword() {
  return (
    <Card id="change-password">
      <SuiBox p={3}>
        <SuiTypography variant="h5">Add Patient Lab Results</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Is Covid 19 Positive ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="me" control={<Radio />} label="Yes" />
                  <FormControlLabel value="dependent" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Patient Blood Presure ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="1" control={<Radio />} label="Low" />
                  <FormControlLabel value="2" control={<Radio />} label="Normal" />
                  <FormControlLabel value="3" control={<Radio />} label="High" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Does Patient Have Malarie ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="me" control={<Radio />} label="Yes" />
                  <FormControlLabel value="dependent" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Enter Patient Blood Pressure" placeholder="180/120" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Enter Typiod test" placeholder="Cold, Cough, Headache" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="If Patient Taken other tests, Name them "
              placeholder="Covid 19, Thypoid"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SuiButton variant="gradient" size="small" color="primary">
              Add
            </SuiButton>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default ChangePassword;
