/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import heart from "assets/images/heart.png";
import logoheart from "assets/images/logoheart.png";
import newheart from "assets/images/newheart.png";
import health from "assets/images/health.png";
// Countries flags

const doctorsdata = [
  {
    title: "Primary Care and Internal MD",
    logo: heart,
    name: "Dr. AshrafKhan",
    Discrepton: "our Doctors Partner with you to help you to reach your welness",
  },
  {
    title: "Emergency Care",
    logo: health,
    name: "Dr. Umar Khan",
    Discrepton: "we provide emergency care for adults and children ",
  },
  {
    name: "Dr. Jhon",
    logo: logoheart,
    title: "Imaging Services",
    Discrepton: "From Xray to MR scan we offer...",
  },
  {
    name: "Dr. David",
    logo: newheart,
    title: "Urgent Care",
    Discrepton: "we offer urgent care for none ...",
  },
];

export default doctorsdata;
