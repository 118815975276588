/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// DoctorProfile page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Footer from "examples/Footer";
import Sidenav from "layouts/pages/Patient/DoctorProfile/components/Sidenav";

import BasicInfo from "layouts/pages/Patient/DoctorProfile/components/BasicInfo";
import ChangePassword from "layouts/pages/Patient/DoctorProfile/components/ChangePassword";
import Authentication from "layouts/pages/Patient/DoctorProfile/components/Authentication";
import OfficeVisit from "layouts/pages/Patient/DoctorProfile/components/officevisit";
import Accounts from "layouts/pages/Patient/DoctorProfile/components/Accounts";
import Header from "./components/Header";

function DoctorProfile() {
  return (
    <DashboardLayout>
      <Header />
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <OfficeVisit />
                </Grid>
                <Grid item xs={12}>
                  <Accounts />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DoctorProfile;
