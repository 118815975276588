/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components

// ProductsList page components
import ProductCell from "layouts/pages/Doctor/Subscription/components/ProductCell";

// Images
import pharmacy1 from "assets/images/pharmacy1.jfif";
import pharmacy2 from "assets/images/pharmacy2.jfif";
import pharmacy3 from "assets/images/pharmacy3.jfif";
import pharmacy4 from "assets/images/pharmacy4.jfif";
import medicine1 from "assets/images/medicine1.jfif";

// Badges

const dataTableDatamedical = {
  columns: [
    {
      Header: "Name",
      accessor: "product",
      width: "40%",
      Cell: ({ value: [name, data] }) => (
        <ProductCell image={data.image} name={name} checked={data.checked} />
      ),
    },
    { Header: "category", accessor: "category" },
    {
      Header: "Service By",
      accessor: "service",
      width: "40%",
      Cell: ({ value: [name, data] }) => <ProductCell image={data.image} name={name} />,
    },
    { Header: "Discount", accessor: "quantity" },
    { Header: "Price", accessor: "price" },
    { Header: "ID", accessor: "sku" },
  ],

  rows: [
    {
      product: ["Esso", { image: medicine1, checked: true }],
      category: "Tablet",
      price: "5 RM",
      sku: 8234,
      quantity: 1,
      service: ["Caring Pharmacy", { image: pharmacy1 }],
    },
    {
      product: ["Esso", { image: medicine1, checked: true }],
      category: "Tablet",
      price: "9 RM",
      sku: 872,
      quantity: 3,

      service: ["OK Pharmacy", { image: pharmacy2 }],
    },
    {
      product: ["Esso", { image: medicine1, checked: false }],
      category: "Tablet",
      price: "7 RM",
      sku: "0134",
      quantity: 5,
      service: ["Hilton Medical", { image: pharmacy3 }],
    },
    {
      product: ["Esso", { image: medicine1, checked: false }],
      category: "Tablet",
      price: "9 RM",
      sku: 113,
      quantity: 5,
      service: ["Hinucion Pharma", { image: pharmacy4 }],
    },
    {
      product: ["Esso", { image: medicine1, checked: false }],
      category: "Tablet",
      price: "20 RM",
      sku: 629,
      quantity: 7,
      service: ["Hamza Medical Pharmacy", { image: pharmacy3 }],
    },
    {
      product: ["Esso", { image: medicine1, checked: false }],
      category: "Tablet",
      price: "20 RM",
      sku: 634729,
      quantity: 0,
      service: ["Food Panda", { image: pharmacy2 }],
    },
    // {
    //   product: ["Y-3 Yohji Yamamoto", { image: yohjiYamamoto, checked: true }],
    //   category: "Shoes",
    //   price: "$869",
    //   sku: 634729,
    //   quantity: 725,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Alexander McQueen", { image: mcqueenShirt, checked: true }],
    //   category: "Clothing",
    //   price: "$1,199",
    //   sku: "00121399",
    //   quantity: 51293,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Luin Floor Lamp", { image: yellowChair, checked: true }],
    //   category: "Furniture",
    //   price: "$1,900",
    //   sku: 434729,
    //   quantity: 34,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Heron Preston T-shirt", { image: heronTshirt, checked: false }],
    //   category: "Clothing",
    //   price: "$149",
    //   sku: 928341,
    //   quantity: 0,
    //   status: "out of stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Gray Living Chair", { image: livingChair, checked: true }],
    //   category: "Furniture",
    //   price: "$2,099",
    //   sku: 9912834,
    //   quantity: 32,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Derbyshire Orange Sofa", { image: orangeSofa, checked: false }],
    //   category: "Furniture",
    //   price: "$2,999",
    //   sku: 561151,
    //   quantity: 22,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Burberry Low-Tops", { image: burberry, checked: true }],
    //   category: "Shoes",
    //   price: "$869",
    //   sku: 634729,
    //   quantity: 725,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Dolce & Gabbana Skirt", { image: dgSkirt, checked: false }],
    //   category: "Designer",
    //   price: "$999",
    //   sku: "01827391",
    //   quantity: 0,
    //   status: "out of stock",
    //   action: <ActionCell />,
    // },
    // {
    //   product: ["Undercover T-shirt", { image: undercover, checked: true }],
    //   category: "Shoes",
    //   price: "$869",
    //   sku: 63472,
    //   quantity: 725,
    //   status: "in stock",
    //   action: <ActionCell />,
    // },
  ],
};

export default dataTableDatamedical;
