/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Default from "layouts/pages/Patient/default";
// import Automotive from "layouts/dashboards/automotive";
// import SmartHome from "layouts/dashboards/smart-home";
// import Analytics from "layouts/applications/analytics";
// import DataTables from "layouts/applications/data-tables";
// import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import Calendar from "examples/Calendar";
// import EditProduct from "layouts/ecommerce/products/edit-product";
// import NewProduct from "layouts/ecommerce/products/new-product";
// import OrderDetails from "layouts/ecommerce/orders/order-details";
// import OrderList from "layouts/ecommerce/orders/order-list";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import Teams from "layouts/pages/profile/teams";
// import AllProjects from "layouts/pages/profile/all-projects";
// import Widgets from "layouts/pages/widgets";
// import Overview from "layouts/ecommerce/overview";
// import ProductPage from "layouts/ecommerce/products/product-page";
// import ProductsList from "layouts/ecommerce/products/products-list";
// import Charts from "layouts/pages/charts";
// import Reports from "layouts/pages/users/reports";
// import NewUser from "layouts/pages/users/new-user";
// import Settings from "layouts/pages/account/settings";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import General from "layouts/pages/projects/general";
// import Timeline from "layouts/pages/projects/timeline";
// import NewProject from "layouts/pages/projects/new-project";
import DoctorDependentsForm from "layouts/pages/Doctor/dependentsForm";
import SignInBasic from "layouts/authentication/sign-in/basic";

import SignUpBasic from "layouts/authentication/sign-up/basic";

import ResetBasic from "layouts/authentication/reset-password/basic";

import LockBasic from "layouts/authentication/lock/basic";

import VerificationBasic from "layouts/authentication/2-step-verification/basic";

import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
// import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import MarketPlace from "layouts/pages/Patient/marketPlace";
import MakeAppointment from "layouts/pages/Patient/makeappoinment";
import PharmacyPlace from "layouts/pages/Patient/pharmacyplace";
import DependentsForm from "layouts/pages/Patient/dependentsForm";
import SearchDoctor from "layouts/pages/Patient/searchDoctor";
import DoctorProfile from "layouts/pages/Patient/DoctorProfile";
import MyAccount from "layouts/pages/Patient/myaccount";
import AccountSettings from "layouts/pages/Patient/accountSettings";
import Hospital from "layouts/pages/Patient/hospital";
import EmailRegistration from "layouts/authentication/sign-up/emailRegistration";
import Registration from "layouts/authentication/registration";
import RegistrationDoctor from "layouts/pages/Doctor/registerdoctor";
import RegistrationHospital from "layouts/pages/Clinic/registerhospital";
import RegistrationPatient from "layouts/pages/Patient/registerpatient";
import FindDoctor from "layouts/pages/Patient/finddoctor";
import Appointment from "layouts/pages/Patient/appointment";
import FindHospital from "layouts/pages/Patient/findHospital";
import DoctorDashboard from "layouts/pages/Doctor/DoctorDashboard";

import DoctorAccount from "layouts/pages/Doctor/DoctorAccount";
import ClinicDashboard from "layouts/pages/Clinic/clinicDashboard";
import ClinicInfo from "layouts/pages/Clinic/clinicInfo";
import DoctorAppointment from "layouts/pages/Doctor/DoctorAppointment";
import MyPatient from "layouts/pages/Doctor/MyPatients";
import DoctorAccountSettings from "layouts/pages/Doctor/DoctorAccountSettings";
import Subscription from "layouts/pages/Doctor/Subscription";
import SmartAssistant from "layouts/pages/Doctor/SmartAssistant";
import DoctorReward from "layouts/pages/Doctor/DoctorReward";
import DDoctorProfile from "layouts/pages/Doctor/DDoctorProfile";
import Chat from "layouts/pages/Patient/chat";
import DocChat from "layouts/pages/Doctor/chat";

const routes = [
  {
    key: "clinicdashboard",
    route: "/clinicdashboard",
    component: <ClinicDashboard />,
  },
  {
    key: "clinicinfo",
    route: "/clinicinfo",
    component: <ClinicInfo />,
  },
  {
    key: "doctorappointment",
    route: "/doctorappointment",

    component: <DoctorAppointment />,
    noCollapse: true,
  },
  {
    key: "mypatient",
    route: "/mypatient",

    component: <MyPatient />,
  },
  {
    key: "myreward",
    route: "/myreward",

    component: <DoctorReward />,
  },
  {
    key: "smartassistan",
    route: "/smartassistan",

    component: <SmartAssistant />,
  },
  {
    key: "doctoraccount",
    route: "/doctoraccount",

    component: <DoctorAccount />,
  },
  {
    key: "doctoraccountsettings",
    route: "/doctoraccountsettings",

    component: <DoctorAccountSettings />,
  },
  {
    key: "subscription",
    route: "/subscription",

    component: <Subscription />,
  },
  {
    key: "myprofile",
    route: "myprofile",

    component: <DDoctorProfile />,
  },
  {
    key: "registration",
    route: "/registration",
    icon: <Shop size="12px" />,
    component: <Registration />,
    noCollapse: true,
  },

  {
    key: "doctorregistration",
    route: "/doctorregistration",
    icon: <Shop size="12px" />,
    component: <RegistrationDoctor />,
    noCollapse: true,
  },
  {
    key: "doctordependent",
    route: "/doctordependent",
    icon: <Shop size="12px" />,
    component: <DoctorDependentsForm />,
    noCollapse: true,
  },

  {
    key: "docchat",
    route: "/docchat",
    icon: <Shop size="12px" />,
    component: <DocChat />,
    noCollapse: true,
  },

  {
    key: "patientregistration",
    route: "/patientregistration",
    icon: <Shop size="12px" />,
    component: <RegistrationPatient />,
    noCollapse: true,
  },
  {
    key: "hospitalregistration",
    route: "/hospitalregistration",
    icon: <Shop size="12px" />,
    component: <RegistrationHospital />,
    noCollapse: true,
  },
  {
    key: "emailregistration",
    route: "/emailregistration",
    icon: <Shop size="12px" />,
    component: <EmailRegistration />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Default />,
    noCollapse: true,
  },

  {
    key: "doctordashboard",
    route: "/doctordashboard",
    component: <DoctorDashboard />,
  },

  {
    type: "collapse",
    name: "Appointments",
    key: "appointment",
    route: "/appointment",
    icon: <CreditCard size="12px" />,
    component: <Appointment />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Find Doctor",
    key: "searchdoctor",
    route: "/searchdoctor",
    icon: <Office size="12px" />,
    component: <SearchDoctor />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Find Clinic",
    key: "findhospital",
    route: "/findhospital",
    icon: <Shop size="12px" />,
    component: <FindHospital />,
    noCollapse: true,
  },
  {
    key: "finddoctor",
    route: "/finddoctor",
    icon: <Shop size="12px" />,
    component: <FindDoctor />,
    noCollapse: true,
  },
  {
    key: "makeappointment",
    route: "/makeappointment",
    icon: <CreditCard size="12px" />,
    component: <MakeAppointment />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Chat",
    key: "chat",
    route: "/chat",
    icon: <CreditCard size="12px" />,
    component: <Chat />,
    noCollapse: true,
  },
  {
    key: "hospital",
    route: "/hospital",
    icon: <CreditCard size="12px" />,
    component: <Hospital />,
    noCollapse: true,
  },

  {
    key: "doctorprofile",
    route: "/doctorprofile",
    icon: <Basket size="12px" />,
    component: <DoctorProfile />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Find MarketPlace",
    key: "marketplace",
    route: "/marketplace",
    icon: <CreditCard size="12px" />,
    component: <MarketPlace />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Find Pharmacy",
    key: "pharmacyplace",
    route: "/pharmacyplace",
    icon: <SpaceShip size="12px" />,
    component: <PharmacyPlace />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "My Dependents",
    key: "mydependents",
    route: "/mydependents",
    icon: <Document size="12px" />,
    component: <DependentsForm />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "My Account",
    key: "myaccount",
    route: "/myaccount",
    icon: <SettingsIcon size="12px" />,
    component: <MyAccount />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "accountsetting",
    route: "/accountsetting",
    icon: <SettingsIcon size="12px" />,
    component: <AccountSettings />,
    noCollapse: true,
  },
  // commented
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Shop size="12px" />,
  //   collapse: [
  //     {
  //       name: "Default",
  //       key: "default",
  //       route: "/dashboards/default",
  //       component: <Default />,
  //     },
  //     {
  //       name: "Automotive",
  //       key: "automotive",
  //       route: "/dashboards/automotive",
  //       component: <Automotive />,
  //     },
  //     {
  //       name: "Smart Home",
  //       key: "smart-home",
  //       route: "/dashboards/smart-home",
  //       component: <SmartHome />,
  //     },
  //   ],
  // },
  // { type: "title", title: "Pages", key: "title-pages" },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Office size="12px" />,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "Teams",
  //           key: "teams",
  //           route: "/pages/profile/teams",
  //           component: <Teams />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "Reports",
  //           key: "reports",
  //           route: "/pages/users/reports",
  //           component: <Reports />,
  //         },
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //         // {
  //         //   name: "Security",
  //         //   key: "security",
  //         //   route: "/pages/account/security",
  //         //   component: <Security />,
  //         // },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "General",
  //           key: "general",
  //           route: "/pages/projects/general",
  //           component: <General />,
  //         },
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //         {
  //           name: "New Project",
  //           key: "new-project",
  //           route: "/pages/projects/new-project",
  //           component: <NewProject />,
  //         },
  //       ],
  //     },
  //     // {
  //     //   name: "Pricing Page",
  //     //   key: "pricing-page",
  //     //   route: "/pages/pricing-page",
  //     //   component: <PricingPage />,
  //     // },
  //     // { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     // {
  //     //   name: "Sweet Alerts",
  //     //   key: "sweet-alerts",
  //     //   route: "/pages/sweet-alerts",
  //     //   component: <SweetAlerts />,
  //     // },
  //     // {
  //     //   name: "Notfications",
  //     //   key: "notifications",
  //     //   route: "/pages/notifications",
  //     //   component: <Notifications />,
  //     // },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <SettingsIcon size="12px" />,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/applications/analytics",
  //       component: <Analytics />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Basket size="12px" />,
  //   collapse: [
  //     {
  //       name: "Overview",
  //       key: "overview",
  //       route: "/ecommerce/overview",
  //       component: <Overview />,
  //     },
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //         {
  //           name: "Products List",
  //           key: "products-list",
  //           route: "/ecommerce/products/products-list",
  //           component: <ProductsList />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //     // {
  //     //   name: "Referral",
  //     //   key: "referral",
  //     //   route: "/ecommerce/referral",
  //     //   component: <Referral />,
  //     // },
  //   ],
  // },
  {
    key: "authentication",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-up/basic",
            component: <SignUpBasic />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/reset-password/basic",
            component: <ResetBasic />,
          },
        ],
      },
      {
        name: "Lock",
        key: "lock",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/lock/basic",
            component: <LockBasic />,
          },
        ],
      },
      {
        name: "2-Step Verification",
        key: "2-step-verification",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/verification/basic",
            component: <VerificationBasic />,
          },
        ],
      },
      {
        name: "Error",
        key: "error",
        collapse: [
          {
            name: "Error 404",
            key: "error-404",
            route: "/authentication/error/404",
            component: <Error404 />,
          },
          {
            name: "Error 500",
            key: "error-500",
            route: "/authentication/error/500",
            component: <Error500 />,
          },
        ],
      },
    ],
  },
  // commented

  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <SpaceShip size="12px" />,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://www.creative-tim.com/learning-lab/react/overview/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://www.creative-tim.com/learning-lab/react/license/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/soft-ui-dashboard/",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://www.creative-tim.com/learning-lab/react/colors/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://www.creative-tim.com/learning-lab/react/grid/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://www.creative-tim.com/learning-lab/react/base-typography/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://www.creative-tim.com/learning-lab/react/borders/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://www.creative-tim.com/learning-lab/react/box-shadows/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://www.creative-tim.com/learning-lab/react/functions/soft-ui-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://www.creative-tim.com/learning-lab/react/routing-system/soft-ui-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <CustomerSupport size="12px" />,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.creative-tim.com/learning-lab/react/alerts/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.creative-tim.com/learning-lab/react/avatar/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.creative-tim.com/learning-lab/react/box/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.creative-tim.com/learning-lab/react/buttons/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.creative-tim.com/learning-lab/react/quill/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.creative-tim.com/learning-lab/react/input/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.creative-tim.com/learning-lab/react/pagination/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.creative-tim.com/learning-lab/react/progress/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Select",
  //       key: "select",
  //       href: "https://www.creative-tim.com/learning-lab/react/select/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Tag Input",
  //       key: "tag-input",
  //       href: "https://www.creative-tim.com/learning-lab/react/tag-input/soft-ui-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/typography/soft-ui-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-soft-ui-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <CreditCard size="12px" />,
  //   noCollapse: true,
  // },
];

export default routes;
