/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTable from "examples/Tables/DataTable";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components

// Data

import grabfood from "assets/images/grabfood.png";

import delivro from "assets/images/delivro.jpg";

import foodPanda from "assets/images/foodpanda.png";
import food1 from "assets/images/food1.jfif";
import dataTableData from "./data/dataTableData1";

function MarketPlace() {
  // DefaultStatisticsCard state for the dropdown value

  // DefaultStatisticsCard state for the dropdown action

  // DefaultStatisticsCard handler for the dropdown action

  // Dropdown menu template for the DefaultStatisticsCard

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox pt={2} px={2}>
              <SuiBox mb={0.5}>
                <SuiTypography variant="h5" color="gradient" fontWeight="medium">
                  Search Marketplaces and order what you need
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={food1}
                    label="Healthy Diet"
                    title="Food Panda"
                    logo={delivro}
                    price="5 RM"
                    description="As Uber works through a huge amount of internal management turmoil."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "primary",
                      label: "Buy Now",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={food1}
                    logo={foodPanda}
                    label="Healthy Diet"
                    title="Grab Food"
                    price="10 RM"
                    description="Music is something that every person has his or ing that every person has his or "
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "primary",
                      label: "Buy Now",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={food1}
                    logo={grabfood}
                    label="Healthy Diet"
                    title="deliveroo"
                    price="15 RM"
                    description="Different people have different taste, and various types of music."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "primary",
                      label: "Buy Now",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={food1}
                    logo={foodPanda}
                    label="Healthy Diet"
                    title="minimalist"
                    price="20 RM"
                    description="Different people have different taste, and various types of music."
                    action={{
                      type: "internal",
                      route: "/pages/profile/profile-overview",
                      color: "primary",
                      label: "Buy Now",
                    }}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </Card>
        </SuiBox>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SuiBox my={3}>
              <Card>
                <SuiBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  paddingTop={3}
                  paddingLeft={3}
                >
                  <SuiBox>
                    <SuiTypography variant="h5" fontWeight="medium">
                      Other results for healthy diet search
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={{
                    defaultValue: 7,
                    entries: [5, 7, 10, 15, 20, 25],
                  }}
                  canSearch
                />
              </Card>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MarketPlace;
