/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Patient from "examples/Lists/Patients";

import Grid from "@mui/material/Grid";
import { useState } from "react";
// Soft UI Dashboard PRO React components
import { Tab, Tabs, Box } from "@mui/material";
import Icon from "@mui/material/Icon";

import Header from "layouts/pages/Doctor/MyPatients/components/Header";

// Soft UI Dashboard PRO React example components
import DoctorDashboardLayout from "examples/LayoutContainers/DoctorDashboardLayout";

import Footer from "examples/Footer";
import doctor2 from "assets/images/doctor2.jpg";
import SuiBox from "components/SuiBox";

// Overview page components

// Data
const ACCOUNT_TABS = [
  {
    value: "Visited Patient",
    icon: (
      <Icon fontSize="medium" color="primary">
        travel_explore
      </Icon>
    ),
    component: (
      <Grid container spacing={1}>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
      </Grid>
    ),
  },
  {
    value: "Rehabilitations",
    icon: (
      <Icon fontSize="medium" color="primary">
        view_list
      </Icon>
    ),
    component: (
      <Grid container spacing={1}>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
        <Grid item lg={4}>
          <Patient image={doctor2} title="Patients" />
        </Grid>
      </Grid>
    ),
  },
];
function MyPatient() {
  // TeamProfileCard dropdown menu handlers
  const [currentTab, setCurrentTab] = useState("Visited Patient");
  return (
    <DoctorDashboardLayout>
      <Header />
      <SuiBox>
        <SuiBox marginLeft="30%">
          <Tabs
            sx={{ width: 400, marginBottom: 2 }}
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            color="primary"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                color="primary"
                disableRipple
                key={tab.value}
                label={tab.value}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
        </SuiBox>
        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </SuiBox>

      <Footer />
    </DoctorDashboardLayout>
  );
}

export default MyPatient;
