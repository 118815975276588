/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { Link } from "react-router-dom";

function Authentication() {
  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiTypography variant="h5">Locations and Appointment</SuiTypography>
      </SuiBox>
      <SuiBox p={3}>
        <SuiBox display="flex" justifyContent="space-between" flexDirection="column">
          <SuiTypography variant="h6" color="text">
            Video Visit
          </SuiTypography>
          <SuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SuiBox mb={{ xs: 1, sm: 2 }}>
              <SuiTypography variant="button" color="text" fontWeight="regular">
                See Doctor from your mobile device instead of an in-person appointment Learn
                <br /> about video visits.
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox mb={{ xs: 1, sm: 0 }}>
            <Link to="/makeappointment">
              <SuiButton marginTop="2" variant="gradient" color="primary" size="medium">
                Schedule a video Visit
              </SuiButton>
            </Link>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Authentication;
