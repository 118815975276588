/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved9 from "assets/images/curved-images/curved0.jpg";
import { Checkbox } from "@mui/material";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout
      title="Welcome to MyPatientHUB!"
      description="We provide smart healthcare services in your hands."
      image={curved9}
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            Sign in to MyPatientHUB
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={2}>
          <Socials />
        </SuiBox>
        <SuiBox p={3}>
          <SuiBox component="form" role="form">
            <SuiBox mb={2}>
              <SuiInput type="email" placeholder="Email or Phone number" />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput type="password" placeholder="Please enter your password" />
            </SuiBox>

            <SuiBox mt={1} mb={1}>
              <SuiButton
                component={Link}
                to="/dashboard"
                variant="gradient"
                color="primary"
                fullWidth
              >
                sign in
              </SuiButton>
            </SuiBox>
            <SuiBox display="flex" mt={-1}>
              <SuiTypography
                variant="overline"
                fontWeight="regular"
                color="secondary"
                alignItems="left"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;Forgot password?
              </SuiTypography>

              <SuiTypography
                variant="overline"
                fontWeight="regular"
                color="secondary"
                onClick={handleSetRememberMe}
                sx={{ marginLeft: 4, cursor: "pointer", userSelect: "none" }}
              >
                <Checkbox fontWeight="small" />
                Remember me
              </SuiTypography>
            </SuiBox>
            <Separator />
            <SuiBox mt={1} mb={3}>
              <SuiButton
                component={Link}
                to="/authentication/sign-up/basic"
                variant="gradient"
                color="info"
                fullWidth
              >
                sign up
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
