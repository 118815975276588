/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { IconButton } from "@mui/material";

function AppointmentRequest({ title, categories, image }) {
  const renderItems = categories.map(({ color, name, description, date }, key) => (
    <SuiBox
      key={name}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      py={1}
      pr={3}
      mb={categories.length - 1 === key ? 0 : 1}
    >
      <SuiBox display="flex" alignItems="center">
        <SuiBox
          component="img"
          src={image}
          alt="message-image"
          borderRadius="50%"
          shadow="md"
          width="25%"
          marginRight={1}
          display="inherit"
        />
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="button" color={color} fontWeight="medium" gutterBottom>
            {name}
          </SuiTypography>
          <SuiTypography variant="caption" color="text">
            {description}
          </SuiTypography>
          <SuiTypography fontSize={12} color={color}>
            {date}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox display="flex" alignItems="center">
        <IconButton variant="text">
          <Icon fontSize="default" color="success">
            checkcircleoutlined
          </Icon>
        </IconButton>
        <IconButton variant="text">
          <Icon fontSize="lg" color="error">
            close
          </Icon>
        </IconButton>
      </SuiBox>
    </SuiBox>
  ));

  return (
    <Card>
      <SuiBox pt={2} px={3} display="flex" justifyContent="space-between">
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
        <Link to="/" style={{ textDecoration: "none" }}>
          <SuiTypography
            variant="h6"
            fontWeight="medium"
            color="success"
            textTransform="capitalize"
          >
            See All
          </SuiTypography>
        </Link>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderItems}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the CategoriesList
AppointmentRequest.propTypes = {
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.string.isRequired,
};

export default AppointmentRequest;
