/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { Divider, Icon, Stack } from "@mui/material";

// Wizard application components

function Address() {
  return (
    <SuiBox>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <Card sx={{ p: 5, boxShadow: 10 }}>
            <SuiTypography variant="h5">Standard Plan</SuiTypography>
            <Divider />
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                check
              </Icon>
              <SuiTypography variant="body2">Detail One</SuiTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                cross
              </Icon>
              <SuiTypography variant="body2">Detail Two</SuiTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                check
              </Icon>
              <SuiTypography variant="body2">Detail Three</SuiTypography>
            </Stack>
          </Card>
        </Grid>
        <Grid item lg={4}>
          <Card sx={{ p: 5, boxShadow: 10 }}>
            {" "}
            <SuiTypography variant="h5">Premium Plan</SuiTypography>
            <Divider />
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                check
              </Icon>
              <SuiTypography variant="body2">Detail One</SuiTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                check
              </Icon>
              <SuiTypography variant="body2">Detail Two</SuiTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                check
              </Icon>
              <SuiTypography variant="body2">Detail Three</SuiTypography>
            </Stack>
          </Card>
        </Grid>
        <Grid item lg={4}>
          <Card sx={{ p: 5, boxShadow: 10 }}>
            <SuiTypography variant="h5">Super Plan</SuiTypography>
            <Divider />
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                check
              </Icon>
              <SuiTypography variant="body2">Detail One</SuiTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                check
              </Icon>
              <SuiTypography variant="body2">Detail Two</SuiTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Icon fontSize="medium" color="primary">
                check
              </Icon>
              <SuiTypography variant="body2">Detail Three</SuiTypography>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Address;
