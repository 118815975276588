/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Accounts() {
  return (
    <Card id="accounts">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Insurance Plans Accepted</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          This Provider Accepts the following insurance plans.
        </SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox display="flex" justifyContent="space-between" flexDirection="column">
          <SuiBox
            display="flex"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
            flexDirection="column"
          >
            <SuiTypography variant="button">AXA EMedic Plus</SuiTypography>
            <SuiTypography variant="button">Allianz Diabetic</SuiTypography>
            <SuiTypography variant="button">Manulife Medical Insurance</SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Accounts;
