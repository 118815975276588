/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// prop-types is a library for typechecking of props

// @mui material components
import Card from "@mui/material/Card";

import { Typography } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import doctor2 from "assets/images/doctor2.jpg";

function NextPatient() {
  return (
    <Card>
      <SuiBox pt={2} px={3} display="flex" justifyContent="space-between">
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Next Patient
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox
          component="li"
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderRadius="lg"
          py={1}
          pr={3}
        >
          <SuiBox display="flex" alignItems="center">
            <SuiBox
              component="img"
              src={doctor2}
              alt="message-image"
              borderRadius="50%"
              shadow="md"
              width="15%"
              marginRight={1}
              display="inherit"
            />
            <SuiBox display="flex" flexDirection="column">
              <SuiTypography variant="button" fontWeight="medium" gutterBottom>
                Azam Shah
              </SuiTypography>
              <SuiTypography variant="caption" color="text">
                2235 Avandole Ave Pasadena Okahama 23233
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" justifyContent="space-between">
            <SuiBox
              display="flex"
              alignItems="center"
              sx={{ paddingRight: 5 }}
              flexDirection="column"
            >
              <Typography variant="caption" fontWeight="bold">
                D.O.B
              </Typography>
              <Typography variant="caption"> 15 May 2022</Typography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              sx={{ paddingRight: 5 }}
              flexDirection="column"
            >
              <Typography variant="caption" fontWeight="bold">
                Sex
              </Typography>
              <Typography variant="caption">Male</Typography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              sx={{ paddingRight: 5 }}
              flexDirection="column"
            >
              <Typography variant="caption" fontWeight="bold">
                Weight
              </Typography>
              <Typography variant="caption">70 Kg</Typography>
            </SuiBox>
          </SuiBox>
          <SuiBox display="flex" marginTop={3} alignItems="center" justifyContent="space-between">
            <SuiBox
              display="flex"
              alignItems="center"
              sx={{ paddingRight: 5 }}
              flexDirection="column"
            >
              <Typography variant="caption" fontWeight="bold">
                Height
              </Typography>
              <Typography variant="caption">172 cm</Typography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              sx={{ paddingRight: 5 }}
              flexDirection="column"
            >
              <Typography variant="caption" fontWeight="bold">
                Last Appoinment
              </Typography>
              <Typography variant="caption">18 Aug 2022</Typography>
            </SuiBox>
            <SuiBox
              display="flex"
              alignItems="center"
              sx={{ paddingRight: 5 }}
              flexDirection="column"
            >
              <Typography variant="caption" fontWeight="bold">
                Register Date
              </Typography>
              <Typography variant="caption">18 May 2022</Typography>
            </SuiBox>
          </SuiBox>
          <SuiBox display="flex" marginTop={3}>
            <SuiBox
              style={{
                marginRight: 4,
                backgroundColor: "#FAFB9D",
                paddingRight: 6,
                paddingLeft: 6,
                padding: 5,
                borderRadius: 8,
              }}
            >
              <Typography color="orange">Asthma</Typography>
            </SuiBox>
            <SuiBox
              style={{
                marginRight: 4,
                backgroundColor: "lightblue",
                paddingRight: 6,
                paddingLeft: 6,
                padding: 5,
                borderRadius: 8,
              }}
            >
              <Typography color="blue">Hypotisis</Typography>
            </SuiBox>
            <SuiBox
              style={{
                backgroundColor: "lightblue",
                paddingRight: 6,
                paddingLeft: 6,
                padding: 5,
                borderRadius: 8,
              }}
            >
              <Typography color="blue">Hypotisis</Typography>
            </SuiBox>
          </SuiBox>
          <SuiBox marginTop={2} justifyContent="space-between" display="flex">
            <SuiBox style={{ marginRight: 3 }}>
              <SuiButton variant="gradient" color="primary" size="small">
                (306) 555-0121
              </SuiButton>
            </SuiBox>
            <SuiBox style={{ marginRight: 3 }}>
              <SuiButton variant="gradient" color="primary" size="small">
                DOCUMENTS
              </SuiButton>
            </SuiBox>
            <SuiBox>
              <SuiButton variant="gradient" color="primary" size="small">
                CHAT
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the CategoriesList

export default NextPatient;
