/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SuiButton from "components/SuiButton";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import SuiBadge from "components/SuiBadge";

function Authentication() {
  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiTypography variant="h5">Add Patient Symptoms</SuiTypography>
        <SuiBadge variant="contained" color="success" badgeContent="enabled" container />
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Does Patient have allergies ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="me" control={<Radio />} label="Yes" />
                  <FormControlLabel value="dependent" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Days of being sick ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="1" control={<Radio />} label="1" />
                  <FormControlLabel value="2" control={<Radio />} label="2" />
                  <FormControlLabel value="3" control={<Radio />} label="3" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Is Patient in serverity Mood ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="me" control={<Radio />} label="Yes" />
                  <FormControlLabel value="dependent" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Patient Allergies" placeholder="180/120" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Enter Patient previous LAB result"
              placeholder="Cold, Cough, Headache"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="attach patient Other Reports" placeholder="Covid 19, Thypoid" />
          </Grid>

          <Grid item xs={12} md={6}>
            <SuiButton variant="gradient" size="small" color="primary">
              FINALIZE
            </SuiButton>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default Authentication;
