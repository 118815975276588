/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import CircularProgress from "@mui/material/CircularProgress";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

function OfficeVisit() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCmkgmny08KLFazm8YI30a7MwtrN_qLwi0",
  });

  const center = {
    lat: -3.745,
    lng: -38.523,
  };
  const containerStyle = {
    width: "350px",
    height: "200px",
  };

  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <SuiBox display="flex">
        <SuiBox p={3}>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center">
            <SuiTypography variant="h5">Office Visits</SuiTypography>
          </SuiBox>
          <SuiBox display="flex" justifyContent="space-between" flexDirection="column">
            <SuiBox
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <SuiBox width={334} mb={{ xs: 1, sm: 2 }}>
                <SuiTypography variant="button" color="text" fontWeight="regular">
                  Lot 193, 194 jalan Nilai Square 6, Bandar Baru Nilai , 71800 Nilai, Negeri
                  Sembilan, Malaysia
                </SuiTypography>
                <Grid>
                  <SuiTypography variant="button" color="text" fontWeight="regular">
                    Phone: +606 8500 999
                  </SuiTypography>
                </Grid>
              </SuiBox>
            </SuiBox>
            <SuiBox mb={{ xs: 1, sm: 1 }}>
              <Link to="/makeappointment">
                <SuiButton marginTop="2" variant="gradient" color="primary" size="medium">
                  Schedule an Office Visit
                </SuiButton>
              </Link>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <SuiBox sx={{ marginTop: 3 }}>
          {isLoaded ? (
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
              {/* Child components, such as markers, info windows, etc. */}
            </GoogleMap>
          ) : (
            <CircularProgress color="secondary" />
          )}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default OfficeVisit;
