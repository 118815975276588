/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Grid from "@mui/material/Grid";

import { Button } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React icons

// Soft UI Dashboard PRO React base styles

// Images
import FormField from "layouts/pages/account/components/FormField";
import curved0 from "assets/images/curved-images/curved0.jpg";
import SuiTypography from "components/SuiTypography";

function Header() {
  /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */

  // Remove event listener on cleanup

  return (
    <SuiBox position="relative">
      <DashboardNavbar absolute light />
      <SuiBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.primary.main, 0.6),
              rgba(gradients.primary.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <SuiBox
        sx={{
          position: "relative",
          mt: -27,
          mx: 3,
          py: 2,
        }}
      >
        <Grid container spacing={1}>
          <Grid textAlign="center" item sm={12} xs={12} lg={12}>
            <SuiTypography variant="h2" color="white">
              Find a Clinic
            </SuiTypography>
            <SuiTypography variant="h6" color="white">
              Search Clinics and schedule an appoinment with doctors through Clinic
            </SuiTypography>
          </Grid>
          <Grid item lg={1} />
          <Grid item xs={12} sm={4} lg={3}>
            <FormField placeholder="Search" />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <FormField placeholder="Zip Code or Neigborhood" />
          </Grid>
          <Grid marginTop={1.5} item xs={12} sm={4} lg={1.4}>
            <Button variant="contained" color="primary">
              Current
            </Button>
          </Grid>
          <Grid marginTop={1.5} item xs={12} sm={2} lg={2}>
            <Button variant="contained" color="primary">
              Search
            </Button>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Header;
