/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui core components
import { Divider } from "@mui/material";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Settings page components

function BasicInfo() {
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SuiBox paddingTop={3} paddingLeft={3}>
        <SuiTypography variant="h5">About Me</SuiTypography>
      </SuiBox>
      <SuiBox p={3}>
        <SuiBox mb={2} lineHeight={1}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            DR. Muhammad Shamsir Bin Mohd Aris provides healthcare services in Obstetics and
            Gynaecology...
          </SuiTypography>
        </SuiBox>
        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Address: &nbsp;
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              &nbsp; Lot 193, 194 Bandar Baru, Malaysia
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Phone: &nbsp;
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              &nbsp; +606-0452 454
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Language: &nbsp;
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              &nbsp; English, Malay
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              social: &nbsp; More details
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default BasicInfo;
