/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SuiBadgeDot from "components/SuiBadgeDot";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import mixedChartData from "layouts/pages/Data/mixedChartData";
import gradientLineChartData from "layouts/pages/Data/gradientLineChartData";
import MixedChart from "examples/Charts/MixedChart";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import reportsDoughnutChartData from "layouts/pages/Data/reportsDoughnutChartData";
// Soft UI Dashboard PRO React example components
import HospitalDashboardLayout from "examples/LayoutContainers/HospitalDashboardLayout";
import defaultLineChartData from "layouts/pages/Data/defaultLineChartData";
import Footer from "examples/Footer";

// Overview page components

// Data

function ClinicDashboard() {
  return (
    <HospitalDashboardLayout>
      <DashboardNavbar />
      <SuiBox py={7}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Patient", fontWeight: "medium" }}
                count="1880"
                percentage={{ color: "success", text: "+55%" }}
                icon={{ color: "dark", component: "bed-pulse" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Report", fontWeight: "medium" }}
                count="2423"
                percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "dark", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Inject", fontWeight: "medium" }}
                count="1254"
                percentage={{ color: "success", text: "-2%" }}
                icon={{ color: "dark", component: "watch" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Surgery", fontWeight: "medium" }}
                count="4212"
                percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "dark", component: "image" }}
              />
            </Grid>
          </Grid>
          <Box sx={{ mb: 4 }} />
        </SuiBox>
        <Grid container spacing={1}>
          <Grid item lg={6}>
            <Card style={{ marginBottom: 10 }}>
              <MixedChart title="Average Patients Visit" height="19.20rem" chart={mixedChartData} />

              <Box marginBottom={2}>
                <MiniStatisticsCard
                  title={{ text: "This Month", fontWeight: "medium" }}
                  count="42"
                  percentage={{ color: "success", text: "+10%" }}
                  icon={{ color: "dark", component: "image" }}
                />
              </Box>
              <Box>
                <MiniStatisticsCard
                  title={{ text: "This Year", fontWeight: "medium" }}
                  count="421"
                  percentage={{ color: "success", text: "+5%" }}
                  icon={{ color: "dark", component: "image" }}
                />
              </Box>
            </Card>
          </Grid>
          <Grid item lg={6}>
            <Card style={{ marginBottom: 10 }}>
              <GradientLineChart title="Patient Visit By Gender" chart={gradientLineChartData} />
              <Box marginBottom={2}>
                <MiniStatisticsCard
                  title={{ text: "Total Male", fontWeight: "medium" }}
                  count="42"
                  percentage={{ color: "success", text: "+10%" }}
                  icon={{ color: "dark", component: "image" }}
                />
              </Box>
              <Box>
                <MiniStatisticsCard
                  title={{ text: "Total Female", fontWeight: "medium" }}
                  count="421"
                  percentage={{ color: "success", text: "+5%" }}
                  icon={{ color: "dark", component: "image" }}
                />
              </Box>
            </Card>
          </Grid>
          <Grid item lg={6}>
            <DefaultLineChart
              title="Reported Cases"
              description={
                <SuiBox display="flex" ml={-1}>
                  <SuiBadgeDot color="info" size="sm" badgeContent="Positive" />
                  <SuiBadgeDot color="warning" size="sm" badgeContent="Negative" />
                </SuiBox>
              }
              height={160}
              chart={defaultLineChartData}
            />
          </Grid>
          <Grid item lg={6}>
            <ReportsDoughnutChart
              title="Patient Visit By Department"
              count={{ text: "Visited" }}
              chart={reportsDoughnutChartData}
              tooltip="See the Patient Visit the most"
            />
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </HospitalDashboardLayout>
  );
}

export default ClinicDashboard;
