/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function DeleteAccount() {
  return (
    <Card id="delete-account">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <Icon fontSize="large" color="primary">
            smoke_free
          </Icon>
          <SuiTypography variant="h5">Smoke-Free Policy</SuiTypography>
          <SuiTypography variant="body2">
            Smoke Free Services for in_Room and hospital services
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default DeleteAccount;
