/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components

import Footer from "examples/Footer";

// Wizard page components
import About from "layouts/pages/Doctor/registerdoctor/components/About";
import Header from "layouts/pages/Doctor/registerdoctor/components/Header";

function RegistrationDoctor() {
  return (
    <>
      <Header />
      <SuiBox pt={3} pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <SuiBox p={2}>
                <SuiBox>
                  <About />
                  <SuiBox
                    style={{ marginBottom: 2 }}
                    mt={3}
                    width="100%"
                    display="flex"
                    justifyContent="right"
                  >
                    <SuiButton
                      component={Link}
                      to="/doctordashboard"
                      variant="gradient"
                      color="dark"
                    >
                      next
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </>
  );
}

export default RegistrationDoctor;
