/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Settings page components
import Header from "layouts/pages/Clinic/clinicInfo/components/Header";
import Sidenav from "layouts/pages/Clinic/clinicInfo/components/Sidenav";

import BasicInfo from "layouts/pages/Clinic/clinicInfo/components/BasicInfo";
import ChangePassword from "layouts/pages/Clinic/clinicInfo/components/ChangePassword";
import Authentication from "layouts/pages/Clinic/clinicInfo/components/Authentication";
import Accounts from "layouts/pages/Clinic/clinicInfo/components/Accounts";
import Notifications from "layouts/pages/Clinic/clinicInfo/components/Notifications";
import Sessions from "layouts/pages/Clinic/clinicInfo/components/Sessions";
import DeleteAccount from "layouts/pages/Clinic/clinicInfo/components/DeleteAccount";

import Footer from "examples/Footer";
import HospitalDashboardLayout from "examples/LayoutContainers/HospitalDashboardLayout";
import Services from "./components/Services";

function ClinicInfo() {
  return (
    <HospitalDashboardLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12}>
            <Header />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ChangePassword />
              </Grid>
              <Grid item xs={12}>
                <Services />
              </Grid>
              <Grid item xs={12}>
                <Authentication />
              </Grid>
              <Grid item xs={12}>
                <Accounts />
              </Grid>
              <Grid item xs={12}>
                <Notifications />
              </Grid>
              <Grid item xs={12}>
                <Sessions />
              </Grid>
              <Grid item xs={12}>
                <DeleteAccount />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </HospitalDashboardLayout>
  );
}

export default ClinicInfo;
