/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";

const categoriesListData = [
  {
    color: "dark",
    icon: "devices_other",
    name: "Dr.Mustafa",
    description: (
      <SuiTypography variant="caption" color="text" fontWeight="medium">
        Emergency
      </SuiTypography>
    ),
    date: "Tuesday, April 5",
    route: "/",
  },
  {
    color: "dark",
    icon: "settings",
    name: "DR. KHIRULLAH",
    description: (
      <SuiTypography variant="caption" color="text" fontWeight="medium">
        consultaion
      </SuiTypography>
    ),
    date: "Friday, November 2",
    route: "/",
  },
  {
    color: "dark",
    icon: "info",
    name: "DR. Bilal",
    description: (
      <SuiTypography variant="caption" color="text" fontWeight="medium">
        Eximination
      </SuiTypography>
    ),
    date: "Monday, March 9",
    route: "/",
  },
];

export default categoriesListData;
