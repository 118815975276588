/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import ReactSwipeButton from "react-swipe-button";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import curved6 from "assets/images/curved-images/curved6.jpg";
import { useState } from "react";

function Basic() {
  const [showResult, setShowResult] = useState(false);

  const onSucces = () => {
    setShowResult(true);
  };

  return (
    <BasicLayout
      title="Welcome to MyPatientHUB!"
      description="We provide smart healthcare services in your hands."
      image={curved6}
    >
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <SuiTypography variant="h5" fontWeight="medium">
            Register with
          </SuiTypography>
        </SuiBox>
        <SuiBox mb={2}>
          <Socials />
        </SuiBox>
        <Separator />
        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox component="form" role="form">
            <SuiBox mb={2}>
              <SuiInput type="phone" placeholder="Phone number" />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput type="password" placeholder="Password" />
            </SuiBox>
            {showResult ? null : (
              <SuiBox sx={{ marginBottom: 10, innerHeight: 10 }}>
                <ReactSwipeButton
                  mb={2}
                  text="Slide for SMS code"
                  color="#33AAE8 "
                  onSuccess={onSucces}
                />
              </SuiBox>
            )}
            {showResult ? (
              <SuiBox>
                {" "}
                <SuiTypography
                  fontWeight="regular"
                  textGradient
                  variant="caption"
                  color="secondary"
                >
                  SMS has been Sent to this number
                </SuiTypography>
                <SuiInput type="password" placeholder="Enter SMS Code" />{" "}
              </SuiBox>
            ) : null}

            <SuiBox mt={4}>
              <SuiButton
                component={Link}
                to="/registration"
                variant="gradient"
                color="primary"
                fullWidth
              >
                sign up
              </SuiButton>
              <SuiBox sx={{ marginTop: 1 }} display="flex" alignItems="center">
                <SuiTypography
                  variant="caption"
                  fontWeight="regular"
                  textGradient
                  color="secondary"
                >
                  By clicking Sign Up; I agree to MyPiHUB Terms of Use and Privacy Policy
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <Separator />
            <SuiBox mt={0} mb={1}>
              <SuiButton
                component={Link}
                to="/emailregistration"
                variant="gradient"
                color="info"
                fullWidth
              >
                sign up with email
              </SuiButton>
            </SuiBox>
            <SuiBox mt={3} textAlign="center">
              <SuiTypography variant="button" color="text" fontWeight="regular">
                Already member?&nbsp;
                <SuiTypography
                  component={Link}
                  to="/authentication/sign-in/basic"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
