/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const calendarEventsData = [
  {
    title: "DR. Ibrahim Visit",
    start: "2022-04-25",
    end: "2022-04-26",
    className: "success",
  },

  {
    title: "Blood Bank Visit",
    start: "2022-04-03",
    end: "2022-04-03",
    className: "info",
  },

  {
    title: "Dr. Madiha Visit",
    start: "2022-04-04",
    end: "2022-04-04",
    className: "warning",
  },

  {
    title: "DR. Shamsir Visit",
    start: "2022-04-05",
    end: "2022-04-05",
    className: "error",
  },

  {
    title: "Apptms DR. Ali Visit",
    start: "2022-04-09",
    end: "2022-04-11",
    className: "warning",
  },

  {
    title: "Approved Aptms",
    start: "2022-04-12",
    end: "2022-04-12",
    className: "primary",
  },

  {
    title: "Lab Checkup",
    start: "2022-04-21",
    end: "2022-04-21",
    className: "error",
  },

  {
    title: "DR. Bilal Visit",
    start: "2022-04-25",
    end: "2022-04-25",
    className: "info",
  },
];

export default calendarEventsData;
