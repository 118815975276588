/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
// Wizard application components

import FormField from "layouts/pages/Patient/dependentsForm/components/FormField";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
// Images

function About() {
  return (
    <SuiBox>
      <SuiBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5" fontWeight="regular">
            Doctor Registration
          </SuiTypography>
        </SuiBox>
        <SuiTypography variant="body2" fontWeight="regular" color="text">
          Please fill in the following form to create your account.
        </SuiTypography>
      </SuiBox>
      <SuiBox mt={1}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={6}>
            <SuiBox mb={2}>
              <FormField type="text" label="first name" placeholder="Eg. Azam" />
            </SuiBox>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <SuiBox mb={2}>
              <FormField type="text" label="last name" placeholder="Eg. Shah" />
            </SuiBox>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      birth date
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect placeholder="February" options={selectData.birthDate} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiSelect placeholder={1} options={selectData.days} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiSelect placeholder={2021} options={selectData.years} />
                </SuiBox>
              </Grid>
              <Grid item xs={6} sm={4}>
                <SuiBox>
                  <FormField type="text" label="My Address" placeholder="Choose Location" />
                </SuiBox>
              </Grid>
              <Grid item xs={6} sm={4}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Specility
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect placeholder="Male" options={selectData.specilities} />
                </SuiBox>
              </Grid>
              <Grid item xs={6} sm={4}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Blood Group
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect placeholder="A+" options={selectData.bloodGroups} />
                </SuiBox>
              </Grid>
              <Grid item xs={6} sm={4}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      I&apos;m
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect placeholder="Male" options={selectData.gender} />
                </SuiBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default About;
