/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React icons

// Soft UI Dashboard PRO React base styles

// Images

import curved0 from "assets/images/curved-images/curved0.jpg";
import SuiTypography from "components/SuiTypography";

function Header() {
  /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */

  // Remove event listener on cleanup

  return (
    <SuiBox position="relative">
      <DashboardNavbar absolute light />
      <SuiBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="13.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.primary.main, 0.6),
              rgba(gradients.primary.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <SuiBox
        sx={{
          position: "relative",
          mt: -27,
          mx: 3,
          py: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid marginTop={10} textAlign="center" item sm={12} xs={12} lg={12}>
            <SuiTypography variant="h2" color="white">
              Scheduale Appointment
            </SuiTypography>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Header;
