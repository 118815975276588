/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from "react";

// Soft UI Dashboard PRO React components
import { Tab, Tabs, Box, Icon } from "@mui/material";
import Menu from "@mui/material/Menu";
import Calendar from "react-calendar";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import categoriesListData from "layouts/pages/Data/categoriesListData";
import newchart from "layouts/pages/Data/newchart";
import CategoriesList from "examples/Lists/CategoriesList";
import NewCategoriesList from "examples/Lists/NewCategoriesList";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ReactApexChart from "react-apexcharts";

// Soft UI Dashboard PRO React example components
import DoctorDashboardLayout from "examples/LayoutContainers/DoctorDashboardLayout";

import Footer from "examples/Footer";
import doctor2 from "assets/images/doctor2.jpg";

import SuiButton from "components/SuiButton";

// Overview page components

// Data

function DoctorAppointment() {
  const [marketingMenu, setMarketingMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const openMarketingMenu = (event) => setMarketingMenu(event.currentTarget);
  const closeMarketingMenu = () => setMarketingMenu(null);

  const renderMarketingMenu = (
    <Menu
      anchorEl={marketingMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(marketingMenu)}
      onClose={closeMarketingMenu}
      keepMounted
    >
      <MenuItem onClick={closeMarketingMenu}>Action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Something else here</MenuItem>
    </Menu>
  );

  const state = {
    series: [
      {
        name: "EMERGENCY",
        data: [44, 55, 41, 37, 22, 43, 21],
      },
      {
        name: "CONSULTATION",
        data: [53, 32, 33, 52, 13, 43, 32],
      },
      {
        name: "EXAMINATION",
        data: [12, 17, 11, 9, 15, 11, 20],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "Overall Appointment Held with Patients",
      },
      xaxis: {
        categories: ["june", "july", "Aug", "Sept", "Oct", "Nov", "Dec"],
      },

      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };
  const ACCOUNT_TABS = [
    {
      value: "Appointments",
      icon: (
        <Icon fontSize="medium" color="primary">
          travel_explore
        </Icon>
      ),
      component: (
        <SuiBox>
          <SuiBox>
            <Box sx={{ mb: 4 }} />
          </SuiBox>
          <Grid container spacing={1}>
            <Grid item lg={6}>
              <Card style={{ padding: 10 }}>
                <ReactApexChart
                  options={state.options}
                  series={state.series}
                  type="bar"
                  height={350}
                />
              </Card>
            </Grid>
            <Grid item lg={6}>
              <Card style={{ marginBottom: 10 }}>
                <GradientLineChart title="Treatments Applied to Patients" chart={newchart} />
              </Card>
            </Grid>
            <Grid item lg={6}>
              <Card style={{ marginBottom: 10 }}>
                <GradientLineChart title="Procedures Assigned for Patients" chart={newchart} />
              </Card>
            </Grid>

            <Grid item lg={6}>
              <SuiBox style={{ marginBottom: 10 }}>
                <NewCategoriesList
                  image={doctor2}
                  title="Previous Appoinments"
                  categories={categoriesListData}
                  dropdown={{
                    action: openMarketingMenu,
                    menu: renderMarketingMenu,
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item lg={6}>
              <CategoriesList
                image={doctor2}
                title="Up Coming Appoinments"
                categories={categoriesListData}
                dropdown={{
                  action: openMarketingMenu,
                  menu: renderMarketingMenu,
                }}
              />
            </Grid>
            <Grid item lg={6}>
              <Calendar borderColor="red" />
            </Grid>
          </Grid>
        </SuiBox>
      ),
    },
    {
      value: "New Booking",
      icon: (
        <Icon fontSize="medium" color="primary">
          view_list
        </Icon>
      ),
      component: (
        <Grid lg={12}>
          <SuiBox style={{ marginLeft: 350, marginTop: 15, marginBottom: "30%" }}>
            <SuiButton variant="gradient" color="primary">
              BOOK AN APPOINTMENT FOR PATIENT
            </SuiButton>
          </SuiBox>
        </Grid>
      ),
    },
  ];
  const [currentTab, setCurrentTab] = useState("Appointments");

  return (
    <DoctorDashboardLayout>
      <DashboardNavbar />
      <SuiBox py={4}>
        <SuiBox marginLeft="30%">
          <Tabs
            sx={{ width: 400 }}
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            color="primary"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                color="primary"
                disableRipple
                key={tab.value}
                label={tab.value}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
        </SuiBox>
        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </SuiBox>

      <Footer />
    </DoctorDashboardLayout>
  );
}

export default DoctorAppointment;
