/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import { Link } from "react-router-dom";
// // Soft UI Dashboard PRO React components
// import SuiBox from "components/SuiBox";

// import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components

import Footer from "examples/Footer";

import PageLayout from "examples/LayoutContainers/PageLayout";
// Wizard page components
// import About from "layouts/pages/registration/components/About";
import Header from "layouts/authentication/registration/components/Header";

function Registration() {
  return (
    <PageLayout>
      <Header />

      <Footer />
    </PageLayout>
  );
}

export default Registration;
