/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

import mixedChartData from "layouts/pages/Data/mixedChartData";
import gradientLineChartData from "layouts/pages/Data/gradientLineChartData";
import MixedChart from "examples/Charts/MixedChart";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import todayAppoinment from "layouts/pages/Data/todayAppoinment";
import Doctorrating from "layouts/pages/Data/doctorRating";
import TodayAppointment from "examples/Lists/TodayAppointment";
import AppointmentRequest from "examples/Lists/AppointmentRequest";
// Soft UI Dashboard PRO React example components
import DoctorDashboardLayout from "examples/LayoutContainers/DoctorDashboardLayout";

import Footer from "examples/Footer";
import doctor2 from "assets/images/doctor2.jpg";
import RequestAppoinment from "layouts/pages/Data/requestAppointment";
import DoctorRating from "examples/Lists/DoctorRating";
import NextPatient from "examples/Lists/NextPatient";

// Overview page components

// Data

function DoctorDashboard() {
  return (
    <DoctorDashboardLayout>
      <DashboardNavbar />
      <SuiBox py={7}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Patient", fontWeight: "medium" }}
                count="1880"
                percentage={{ color: "success", text: "+55%" }}
                icon={{ color: "dark", component: "bed-pulse" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Income", fontWeight: "medium" }}
                count="$2423"
                percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "dark", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Appointments", fontWeight: "medium" }}
                count="1254"
                percentage={{ color: "success", text: "-2%" }}
                icon={{ color: "dark", component: "watch" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MiniStatisticsCard
                title={{ text: "Treatment", fontWeight: "medium" }}
                count="4212"
                x
                percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "dark", component: "image" }}
              />
            </Grid>
          </Grid>
          <Box sx={{ mb: 4 }} />
        </SuiBox>
        <Grid container spacing={1}>
          <Grid item lg={6}>
            <Card style={{ marginBottom: 10 }}>
              <MixedChart title="Revenues" height="19.20rem" chart={mixedChartData} />

              <Box marginBottom={2}>
                <MiniStatisticsCard
                  title={{ text: "This Month", fontWeight: "medium" }}
                  count="42900"
                  percentage={{ color: "success", text: "+10%" }}
                  icon={{ color: "dark", component: "image" }}
                />
              </Box>
              <Box>
                <MiniStatisticsCard
                  title={{ text: "This Year", fontWeight: "medium" }}
                  count="343130"
                  percentage={{ color: "success", text: "+5%" }}
                  icon={{ color: "dark", component: "image" }}
                />
              </Box>
            </Card>
          </Grid>
          <Grid item lg={6}>
            <Card style={{ marginBottom: 10 }}>
              <GradientLineChart title="Patient Visit By Gender" chart={gradientLineChartData} />
              <Box marginBottom={2}>
                <MiniStatisticsCard
                  title={{ text: "Total Male", fontWeight: "medium" }}
                  count="42"
                  percentage={{ color: "success", text: "+10%" }}
                  icon={{ color: "dark", component: "image" }}
                />
              </Box>
              <Box>
                <MiniStatisticsCard
                  title={{ text: "Total Female", fontWeight: "medium" }}
                  count="421"
                  percentage={{ color: "success", text: "+5%" }}
                  icon={{ color: "dark", component: "image" }}
                />
              </Box>
            </Card>
          </Grid>
          <Grid item lg={6}>
            <AppointmentRequest
              image={doctor2}
              title="Appoinment Request"
              categories={RequestAppoinment}
            />
          </Grid>
          <Grid item lg={6}>
            <TodayAppointment
              image={doctor2}
              title="Todays Appoinments"
              categories={todayAppoinment}
            />
          </Grid>
          <Grid item lg={6}>
            <DoctorRating image={doctor2} title="Doctor Rating" categories={Doctorrating} />
          </Grid>
          <Grid item lg={6}>
            <NextPatient image={doctor2} title="Doctor Rating" categories={Doctorrating} />
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DoctorDashboardLayout>
  );
}

export default DoctorDashboard;
