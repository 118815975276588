/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const newchart = {
  labels: [1, 3.75, 6.5, 9.25, 12],
  datasets: [
    {
      label: "Total Male",
      color: "warning",
      data: [50, 40, 60, 150, 20, 160, 150, 170, 50],
    },
    {
      label: "Total Female",
      color: "dark",
      data: [30, 90, 40, 100, 50, 150, 80, 160, 150],
    },
  ],
};

export default newchart;
