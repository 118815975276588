/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import "react-chat-widget/lib/styles.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Widget, addResponseMessage } from "react-chat-widget";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiSelect from "components/SuiSelect";

import SuiTypography from "components/SuiTypography";
import FormField from "layouts/pages/Patient/dependentsForm/components/FormField";
// import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { Card, Divider } from "@mui/material";
import SuiAvatar from "components/SuiAvatar";
import doctor1 from "assets/images/doctor-avatar.jpg";
import SuiButton from "components/SuiButton";
import PaymentMethod from "../PaymentMethod/index";
// Soft UI Dashboard PRO React icons

function Account() {
  useEffect(() => {
    addResponseMessage("Welcome to this awesome chat!");
  }, []);
  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
  };

  return (
    <SuiBox>
      <SuiBox width="80%">
        <SuiBox mb={1}>
          <SuiTypography variant="h4" fontWeight="regular">
            Schedual an Appointment
          </SuiTypography>
        </SuiBox>
      </SuiBox>

      <Grid container spacing={3}>
        <Grid item sm={12} xs={12} lg={7}>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xs={12}>
              {" "}
              <SuiTypography>Who is taking appointment?</SuiTypography>
            </Grid>
            <Grid item lg={6} sm={6}>
              <SuiBox style={{ marginLeft: 10 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel value="me" control={<Radio />} label="Me" />
                    <FormControlLabel value="dependent" control={<Radio />} label="My Dependent" />
                  </RadioGroup>
                </FormControl>
              </SuiBox>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              {" "}
              <SuiTypography>Insurance Information</SuiTypography>
            </Grid>
            <Grid item lg={6} sm={6}>
              <SuiBox>
                <FormField type="text" label="Insurance Company and Plan" />
              </SuiBox>
            </Grid>
            <Grid item lg={6} sm={4}>
              <SuiBox mb={1}>
                <FormField type="text" label="Insurance Member ID " />
              </SuiBox>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              {" "}
              <SuiTypography>Personal Information</SuiTypography>
            </Grid>
            <Grid item lg={6} sm={6}>
              <SuiBox>
                <FormField type="text" label="first name" placeholder="Eg. Michael" />
              </SuiBox>
            </Grid>
            <Grid item lg={6} sm={4}>
              <SuiBox>
                <FormField type="text" label="last name" placeholder="Eg. Tomson" />
              </SuiBox>
            </Grid>
            <Grid item lg={6} sm={6}>
              <SuiBox>
                <FormField type="email" label="Email" placeholder="Eg.tomson@gmail.com" />
              </SuiBox>
            </Grid>
            <Grid item lg={6} sm={4}>
              <SuiBox>
                <FormField type="email" label="Confirm Email" />
              </SuiBox>
            </Grid>
            <Grid item lg={6} sm={6}>
              <SuiBox>
                <FormField type="text" label="Phone Number" placeholder="+49 403 4394 3" />
              </SuiBox>
            </Grid>
            <Grid item lg={6} sm={4}>
              <SuiBox>
                <FormField type="text" label="Street Address" />
              </SuiBox>
            </Grid>
            <Grid item lg={6} sm={6}>
              <SuiBox style={{ marginLeft: 10 }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
              </SuiBox>
            </Grid>
            <Grid item lg={6} sm={6}>
              <SuiBox>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Phone Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio color="secondary" />}
                      label="Phone"
                    />
                    <FormControlLabel value="male" control={<Radio />} label="Home" />
                    <FormControlLabel value="other" control={<Radio />} label="Work" />
                  </RadioGroup>
                </FormControl>
              </SuiBox>
            </Grid>

            <Grid item lg={4} sm={6}>
              <SuiBox>
                <FormField type="text" label="City" placeholder="X X X X X" />
              </SuiBox>
            </Grid>
            <Grid item lg={4} sm={4}>
              <SuiBox>
                <FormField type="text" label="State" placeholder="X X X X X" />
              </SuiBox>
            </Grid>
            <Grid item lg={4} sm={4}>
              <SuiBox>
                <FormField type="text" label="Zip Code" placeholder="X X X X X" />
              </SuiBox>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <SuiTypography>Visit Information</SuiTypography>
            </Grid>
            <Grid item lg={12} sm={12}>
              <SuiBox>
                <FormField
                  name="description"
                  label="Reason For Visit (Symptoms, Diagonsis, Test Results, or other Information)"
                  multiline
                  rows={3}
                />
              </SuiBox>
            </Grid>
            <Grid item lg={12} sm={12}>
              <SuiBox>
                <FormField
                  type="text"
                  label="Refferring Doctor (Optional)"
                  placeholder="X X X X X"
                />
              </SuiBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} sm={12} xs={12}>
          <Card sx={{ padding: 3 }}>
            <SuiTypography style={{ marginBottom: 5 }}>Appointment Details</SuiTypography>
            <SuiBox display="flex">
              <SuiAvatar
                src={doctor1}
                alt="profile-image"
                variant="rounded"
                size="xxl"
                shadow="lg"
              />
              <SuiBox sx={{ marginLeft: 2 }}>
                {" "}
                <SuiTypography variant="h6">Prof. Dr. Muhammad Shamsir Bin Mohd Aris</SuiTypography>
                <SuiTypography variant="body2" color="text">
                  Obstetics and Gynaecology
                </SuiTypography>
                <SuiTypography variant="body2" color="text">
                  Visiting Consultant
                </SuiTypography>
                <SuiTypography variant="body2" color="primary">
                  Video Visit
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="h6" color="primary">
                Date and Time
              </SuiTypography>
              <SuiTypography variant="body2" color="text">
                {" "}
                Tuesday, March 22 2022 at 8:00
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography variant="h6" color="primary">
                Visit Type
              </SuiTypography>
              <SuiTypography variant="body2" color="text">
                Video Visit
              </SuiTypography>
            </SuiBox>
            <Divider />
            <SuiTypography color="primary" variant="h6">
              About Video Visit
            </SuiTypography>
            <SuiTypography variant="body2" color="text">
              {" "}
              video visit lets you see doctor from your mobile device instead of an in-person
              appointment. <Link to="/video">Learn More About Video Visits </Link> and how to
              prepare
            </SuiTypography>
          </Card>
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            profileAvatar={doctor1}
            profileClientAvatar={doctor1}
            title="Chat With Doctor"
            subtitle="Make Appointment"
          />
          <Card style={{ marginTop: 10, padding: 20 }}>
            <SuiTypography variant="subtitle2">Credit and Debit Cards</SuiTypography>{" "}
            <SuiTypography variant="caption">Transaction fee may apply</SuiTypography>
            <SuiBox>
              <FormField type="text" label="Card Holder Name" placeholder="X X X X X" />
              <FormField type="text" label="Card Number" placeholder="X X X X X" />
            </SuiBox>
            <SuiBox display="flex">
              <SuiBox>
                <FormField type="date" label="Start Date" />
              </SuiBox>
              <SuiBox sx={{ marginLeft: 2 }}>
                <FormField type="date" label="End Date" />
              </SuiBox>
              <SuiBox sx={{ marginLeft: 2 }}>
                <FormField type="text" label="CVV" />
              </SuiBox>
            </SuiBox>
            <SuiButton sx={{ marginTop: 3 }} variant="contained" color="success">
              Pay Now
            </SuiButton>
            <SuiBox sx={{ marginLeft: 2 }}>
              <FormControlLabel
                control={<Checkbox />}
                label="I have read and accept the term of use rules"
              />
            </SuiBox>
          </Card>
          <PaymentMethod />
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Account;
