/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Image
import adobeXD from "assets/images/foodpanda.png";
import atlassian from "assets/images/small-logos/logo-atlassian.svg";
import slack from "assets/images/small-logos/logo-slack.svg";
import spotify from "assets/images/small-logos/logo-spotify.svg";
import jira from "assets/images/small-logos/logo-jira.svg";

const complexReportsDoughnutChartDataPharmacies = {
  images: [adobeXD, atlassian, slack, spotify, jira],
  labels: [
    "ALPRO PHARMACY NILAI",
    "ALPRO PHARMACY PEKAN NILAI",
    "OK PHARMACY",
    "PHARMART PHARMACY NILAI",
    "Health Lane Family Pharmacy",
  ],
  datasets: {
    label: "Pharmacies Usage",
    backgroundColors: ["info", "secondary", "primary", "success", "dark"],
    data: [15, 12, 5, 9, 14],
  },
};

export default complexReportsDoughnutChartDataPharmacies;
