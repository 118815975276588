/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import FormField from "layouts/pages/account/components/FormField";
import { useState } from "react";
import doctor3 from "assets/images/doctor4.jpg";
import doctor1 from "assets/images/doctor-avatar.jpg";
import doctor2 from "assets/images/doctor2.jpg";

import doctor5 from "assets/images/doctor1.jpg";
import doctor4 from "assets/images/doctor5.jpg";
import SuiButton from "components/SuiButton";
// Settings page components

function BasicInfo() {
  const [showResults, setShowResults] = useState(false);
  const onClick = () => {
    setShowResults(!showResults);
  };

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SuiBox px={3} paddingTop={3} lineHeight={1} justifyContent="space-between" display="flex">
        <SuiBox mb={1}>
          <SuiTypography variant="h5"> Doctors</SuiTypography>
        </SuiBox>
        <SuiBox mb={1}>
          <SuiButton variant="gradient" onClick={onClick} color="primary">
            <Icon fontSize="large">add</Icon>
          </SuiButton>
        </SuiBox>
      </SuiBox>
      {showResults ? (
        <Grid container spacing={2} px={3} paddingBottom={2}>
          <Grid item lg={6} sm={6}>
            <FormField label="Title" placeholder="Enter Title" />
          </Grid>
          <Grid item lg={6} sm={6}>
            <FormField label="Details" placeholder="Enter Details" />
          </Grid>
        </Grid>
      ) : null}
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor1}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} variant="body2" color="text">
                  PROF DR. Muhammad shamsir
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor2}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} variant="body2" color="text">
                  PROF DR. MADYA DR. Khairullah
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor5}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} marginBottom={3} variant="body2" color="text">
                  Dr. Mohd Radzniwan
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor3}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} marginBottom={3} variant="body2" color="text">
                  Dr. Fatima Begum
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
              <Grid
                item
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                lg={2}
                m={1}
              >
                <SuiAvatar
                  src={doctor4}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="sm"
                />
                <SuiTypography marginTop={1} marginBottom={3} variant="body2" color="text">
                  Dr. Sharifah Najwa
                </SuiTypography>
                <Link to="/doctorprofile">
                  <SuiButton variant="gradient" color="primary" size="small">
                    View Profile
                  </SuiButton>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default BasicInfo;
