/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import "react-chat-widget/lib/styles.css";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// import SuiSelect from "components/SuiSelect";

import SuiTypography from "components/SuiTypography";
// import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
// Soft UI Dashboard PRO React icons

function Account() {
  return (
    <SuiBox>
      <SuiBox width="80%">
        <SuiBox mb={1}>
          <SuiTypography variant="h4" fontWeight="regular">
            Appointment Schedualed
          </SuiTypography>
        </SuiBox>
      </SuiBox>

      <Grid container spacing={3}>
        <Grid item sm={12} xs={12} lg={7}>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xs={12}>
              {" "}
              <SuiTypography>A confirmation eamil has been sent to your inbox.</SuiTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Account;
