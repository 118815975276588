const newdoctorsdata = [
  {
    title: "Anesthesiology",

    name: "Dr. AshrafKhan",
  },
  {
    title: "Dermatology",

    name: "Dr. Umar Khan",
  },
  {
    name: "Dr. Jhon",

    title: "Emergency medicine",
  },
  {
    name: "Dr. Wasim",

    title: "Neurology",
  },
  {
    name: "Dr. Jhon",

    title: "Consultation",
  },
  {
    name: "Dr. Ahmad",

    title: "Ophthalmology",
  },
];

export default newdoctorsdata;
