/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SuiButton from "components/SuiButton";
import doctor2 from "assets/images/doctor2.jpg";

function Sessions() {
  const [marketingMenu, setMarketingMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const openMarketingMenu = (event) => setMarketingMenu(event.currentTarget);
  const closeMarketingMenu = () => setMarketingMenu(null);

  const renderMarketingMenu = (
    <Menu
      anchorEl={marketingMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(marketingMenu)}
      onClose={closeMarketingMenu}
      keepMounted
    >
      <MenuItem onClick={closeMarketingMenu}>Action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMarketingMenu}>Something else here</MenuItem>
    </Menu>
  );

  const dropdown = {
    action: openMarketingMenu,
    menu: renderMarketingMenu,
  };

  return (
    <Card id="sessions">
      <SuiBox p={2}>
        <SuiBox
          component="li"
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderRadius="lg"
          py={1}
          pr={4}
        >
          <SuiBox display="flex" alignItems="center" justifyContent="space-between">
            <SuiBox marginRight={26} display="flex">
              <SuiBox
                component="img"
                src={doctor2}
                alt="message-image"
                borderRadius="50%"
                shadow="md"
                width="15%"
                marginRight={1}
                display="inherit"
              />
              <SuiBox display="flex" flexDirection="column">
                <SuiTypography variant="button" fontWeight="medium" gutterBottom>
                  Azam Shah
                </SuiTypography>
                <SuiTypography variant="caption" color="error">
                  EMERGENCY
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox>
              <SuiTypography fontSize={10}>Monday, November 2</SuiTypography>
            </SuiBox>
            <SuiBox>
              {dropdown && (
                <SuiTypography
                  color="secondary"
                  onClick={dropdown.action}
                  sx={{
                    width: "16px",
                    cursor: "pointer",
                  }}
                >
                  <Icon fontSize="default">more_vert</Icon>
                </SuiTypography>
              )}
              {dropdown.menu}
            </SuiBox>
          </SuiBox>
        </SuiBox>

        <SuiBox display="flex" marginLeft={25} alignItems="center">
          <SuiBox
            display="flex"
            alignItems="center"
            sx={{ paddingRight: 5 }}
            flexDirection="column"
          >
            <Typography variant="caption" fontWeight="bold">
              Height
            </Typography>
            <Typography variant="caption">172 cm</Typography>
          </SuiBox>
          <SuiBox
            display="flex"
            alignItems="center"
            sx={{ paddingRight: 5 }}
            flexDirection="column"
          >
            <Typography variant="caption" fontWeight="bold">
              Last Appoinment
            </Typography>
            <Typography variant="caption">18 Aug 2022</Typography>
          </SuiBox>
          <SuiBox
            display="flex"
            alignItems="center"
            sx={{ paddingRight: 5 }}
            flexDirection="column"
          >
            <Typography variant="caption" fontWeight="bold">
              Register Date
            </Typography>
            <Typography variant="caption">18 May 2022</Typography>
          </SuiBox>
        </SuiBox>
        <SuiBox display="flex" marginLeft={25} marginTop={3} alignItems="center">
          <SuiBox
            display="flex"
            alignItems="center"
            sx={{ paddingRight: 7 }}
            flexDirection="column"
          >
            <Typography variant="caption" fontWeight="bold">
              D.O.B
            </Typography>
            <Typography variant="caption"> 15 May 2022</Typography>
          </SuiBox>
          <SuiBox
            display="flex"
            alignItems="center"
            sx={{ paddingRight: 12 }}
            flexDirection="column"
          >
            <Typography variant="caption" fontWeight="bold">
              Sex
            </Typography>
            <Typography variant="caption">Male</Typography>
          </SuiBox>
          <SuiBox
            display="flex"
            alignItems="center"
            sx={{ paddingRight: 5 }}
            flexDirection="column"
          >
            <Typography variant="caption" fontWeight="bold">
              Weight
            </Typography>
            <Typography variant="caption">70 Kg</Typography>
          </SuiBox>
        </SuiBox>

        <Divider />
        <SuiBox marginTop={2} display="flex" justifyContent="center">
          <SuiBox style={{ marginRight: 3 }}>
            <SuiButton variant="gradient" color="primary" size="small">
              Treatments
            </SuiButton>
          </SuiBox>
          <SuiBox style={{ marginRight: 3 }}>
            <SuiButton variant="gradient" color="primary" size="small">
              To Pharmacy
            </SuiButton>
          </SuiBox>
          <SuiBox>
            <SuiButton variant="gradient" color="primary" size="small">
              To InPatient
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Sessions;
