/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Divider, Typography } from "@mui/material";
// Soft UI Dashboard PRO React components
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Collections from "@mui/icons-material/Collections";
import Send from "@mui/icons-material/Send";
import Call from "@mui/icons-material/Call";
import MoreVert from "@mui/icons-material/MoreVert";
import Videocam from "@mui/icons-material/Videocam";
import KeyboardVoice from "@mui/icons-material/KeyboardVoice";
import EmojiEmotions from "@mui/icons-material/EmojiEmotions";
import Edit from "@mui/icons-material/Edit";
import SuiTypography from "components/SuiTypography";

import List from "@mui/material/List";
// Soft UI Dashboard PRO React example components
import DoctorDashboardLayout from "examples/LayoutContainers/DoctorDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SuiBox from "components/SuiBox";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FixedSizeList } from "react-window";

import doctor2 from "../../../../assets/images/doctor2.jpg";
import doctoravatar from "../../../../assets/images/doctor-avatar.jpg";

// Images
function renderRow() {
  return (
    <List
      secondaryAction={<SuiTypography>2/2/2022</SuiTypography>}
      sx={{
        width: "100%",
        maxWidth: 360,

        bgcolor: "background.paper",
        alignItems: "center",
      }}
    >
      <ListItemButton alignItems="center">
        <Grid container>
          <Grid item lg={10} display="flex" alignItems="center">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src={doctor2} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontSize: 16, fontWeight: "medium" }}
              primary="noman khan"
              secondary={<fragment>short message here…</fragment>}
            />
          </Grid>
          <Grid item lg={2}>
            <Typography variant="caption" color="caption">
              2:30
            </Typography>
          </Grid>
        </Grid>
      </ListItemButton>
    </List>
  );
}
function renderChat() {
  return (
    <List
      secondaryAction={<SuiTypography>2/2/2022</SuiTypography>}
      sx={{
        width: "100%",
        maxWidth: 800,

        bgcolor: "background.paper",
        alignItems: "center",
      }}
      disablePadding
    >
      <ListItemButton alignItems="center">
        <Grid container>
          <Grid item lg={9} display="flex" alignItems="center">
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src={doctoravatar} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ fontSize: 11, fontWeight: "medium" }}
              primary="noman khan"
              variant="caption"
              secondary={<fragment>Hi hope you feel better</fragment>}
            />
          </Grid>
          <Grid item lg={2}>
            <Typography variant="caption" color="caption">
              2:30
            </Typography>
          </Grid>
        </Grid>
      </ListItemButton>
    </List>
  );
}

function DocChat() {
  const [showResult, setShowResult] = React.useState(false);
  const onClick = () => {
    setShowResult(!showResult);
  };
  return (
    <DoctorDashboardLayout>
      <DashboardNavbar />
      <Card marginBottom={10}>
        <Grid container>
          <Grid item lg={3.5} sm={4} xs={4}>
            <SuiBox borderRight={0.4} borderColor="#F5F5DC">
              <SuiBox alignItems="center" padding={2}>
                <SuiBox display="flex" justifyContent="space-between">
                  <SuiBox display="flex" alignItems="center">
                    <SuiBox
                      component="img"
                      src={doctor2}
                      alt="message-image"
                      borderRadius="50%"
                      shadow="md"
                      width="20%"
                      marginRight={1}
                      display="inherit"
                    />
                    <SuiBox display="flex" flexDirection="column">
                      <SuiTypography
                        variant="button"
                        color="caption"
                        fontWeight="medium"
                        gutterBottom
                      >
                        Pro Murtaza
                      </SuiTypography>
                      <SuiTypography variant="caption" color="text">
                        online
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                  <IconButton onClick={onClick} sx={{ p: "10px" }} aria-label="search">
                    <Edit />
                  </IconButton>
                </SuiBox>
                <Paper
                  component="form"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center", marginTop: 1 }}
                >
                  <InputBase
                    sx={{ flex: 1, borderRadius: 2 }}
                    placeholder="Search Contacts"
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                  <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </SuiBox>

              <Box
                sx={{
                  width: "20%",
                  height: 370,
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <FixedSizeList
                  height={370}
                  width={297}
                  itemSize={30}
                  itemCount={3}
                  overscanCount={5}
                >
                  {renderRow}
                </FixedSizeList>
              </Box>
            </SuiBox>
          </Grid>
          {showResult ? (
            <Grid item lg={8} sm={8} xs={8}>
              <SuiBox
                width={725}
                display="flex"
                alignItems="center"
                borderRadius={10}
                shadow="md"
                justifyContent="space-between"
                padding={4}
                marginBottom={1}
                height={30}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1, borderRadius: 2 }}
                  placeholder="To: Recipients"
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
              </SuiBox>

              <Paper
                component="form"
                sx={{
                  marginTop: "58%",
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 725,
                  borderRadius: 3,
                  shadow: "md",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <EmojiEmotions />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1, borderRadius: 8 }}
                  placeholder="Enter Message"
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton color="primary" type="submit" sx={{ p: "10px" }} aria-label="search">
                  <Send />
                </IconButton>
                <IconButton color="primary" type="submit" sx={{ p: "10px" }} aria-label="search">
                  <KeyboardVoice />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton sx={{ p: "10px" }} aria-label="directions">
                  <Collections />
                </IconButton>
              </Paper>
            </Grid>
          ) : (
            <Grid item lg={8} sm={8} xs={8}>
              <SuiBox
                width={725}
                display="flex"
                alignItems="center"
                borderRadius={10}
                shadow="md"
                justifyContent="space-between"
                padding={4}
                marginBottom={1}
                height={30}
              >
                <SuiBox display="flex" alignItems="center">
                  <SuiBox
                    component="img"
                    src={doctoravatar}
                    borderRadius="50%"
                    shadow="md"
                    width="15%"
                    marginRight={1}
                    display="inherit"
                  />
                  <SuiBox display="flex" flexDirection="column">
                    <SuiTypography
                      variant="button"
                      color="caption"
                      fontWeight="medium"
                      gutterBottom
                    >
                      ahmadi khan
                    </SuiTypography>
                    <SuiTypography variant="caption" color="text">
                      online
                    </SuiTypography>
                  </SuiBox>
                </SuiBox>
                <SuiBox>
                  <IconButton color="primary" type="submit" sx={{ p: "10px" }} aria-label="search">
                    <Videocam />
                  </IconButton>
                  <IconButton color="primary" type="submit" sx={{ p: "10px" }} aria-label="search">
                    <Call />
                  </IconButton>
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <MoreVert />
                  </IconButton>
                </SuiBox>
              </SuiBox>
              <Box
                sx={{
                  width: "40%",
                  height: 400,
                  maxWidth: 650,
                  bgcolor: "background.paper",
                }}
              >
                <FixedSizeList
                  height={400}
                  width={800}
                  itemSize={10}
                  itemCount={3}
                  overscanCount={5}
                >
                  {renderChat}
                </FixedSizeList>
              </Box>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 725,
                  borderRadius: 3,
                  shadow: "md",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <EmojiEmotions />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1, borderRadius: 8 }}
                  placeholder="Enter Message"
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton color="primary" type="submit" sx={{ p: "10px" }} aria-label="search">
                  <Send />
                </IconButton>
                <IconButton color="primary" type="submit" sx={{ p: "10px" }} aria-label="search">
                  <KeyboardVoice />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton sx={{ p: "10px" }} aria-label="directions">
                  <Collections />
                </IconButton>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Card>
      <Footer />
    </DoctorDashboardLayout>
  );
}

export default DocChat;
