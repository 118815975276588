/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import SuiInput from "components/SuiInput";
import Card from "@mui/material/Card";
import SuiTypography from "components/SuiTypography";
import { Box, Tab, Tabs, Divider } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SuiAvatar from "components/SuiAvatar";
import Footer from "examples/Footer";
import Header from "layouts/pages/Patient/searchDoctor/components/Header";
// Overview page components
import doctor1 from "assets/images/doctor-avatar.jpg";
import doctor2 from "assets/images/doctor2.jpg";

// Data

import selectData from "./data/selectData";

const center = {
  lat: 3.139,
  lng: 101.6879,
};
const marker8 = {
  lat: 3.199,
  lng: 101.4969,
};
const marker7 = {
  lat: 3.938,
  lng: 101.4869,
};
const marker6 = {
  lat: 3.339,
  lng: 101.5969,
};
const marker5 = {
  lat: 3.149,
  lng: 101.697,
};
const marker4 = {
  lat: 3.149,
  lng: 101.5969,
};
const marker3 = {
  lat: 3.138,
  lng: 101.6869,
};
const marker2 = {
  lat: 3.239,
  lng: 101.4969,
};
const marker1 = {
  lat: 3.139,
  lng: 101.497,
};
const containerStyle = {
  width: "900px",
  height: "600px",
};

const ACCOUNT_TABS = [
  {
    value: "Map",
    icon: (
      <Icon fontSize="medium" color="primary">
        travel_explore
      </Icon>
    ),
    component: (
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {/* Child components, such as markers, info windows, etc. */}
        <Marker position={marker1} />
        <Marker position={marker2} />
        <Marker position={marker3} />
        <Marker position={marker4} />
        <Marker position={marker5} />
        <Marker position={marker6} />
        <Marker position={marker7} />
        <Marker position={marker8} />
      </GoogleMap>
    ),
  },
  {
    value: "List",
    icon: (
      <Icon fontSize="medium" color="primary">
        view_list
      </Icon>
    ),
    component: (
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} lg={12}>
          <Card sx={{ padding: 3 }}>
            <Link to="/doctorprofile">
              <SuiBox display="flex">
                <SuiAvatar
                  src={doctor1}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="lg"
                />
                <SuiBox sx={{ marginLeft: 2 }}>
                  {" "}
                  <SuiTypography variant="h6">
                    Prof. Dr. Muhammad Shamsir Bin Mohd Aris
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    Obstetics and Gynaecology
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    Visiting Consultant
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    Lot 193,194 Jalan Nilai Square 6, Bandar Baru Nilai, 71800 Nilai, Negeri
                    Sembilan Malaysia
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    https://klinikpakar.usim.edu.my
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    +60126504921
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    English, Malay
                  </SuiTypography>
                  <Link to="/makeappointment">
                    <SuiButton color="primary">Next Available: Tuesday, March 22</SuiButton>
                  </Link>
                </SuiBox>
              </SuiBox>
            </Link>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12} lg={12}>
          <Card sx={{ padding: 3 }}>
            <Link to="/doctorprofile">
              <SuiBox display="flex">
                <SuiAvatar
                  src={doctor2}
                  alt="profile-image"
                  variant="rounded"
                  size="xxl"
                  shadow="lg"
                />
                <SuiBox sx={{ marginLeft: 2 }}>
                  {" "}
                  <SuiTypography variant="h6"> PROF DR. MADYA DR. Khairullah </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    Obstetics and Gynaecology
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    Visiting Consultant
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    Lot 193,194 Jalan Nilai Square 6, Bandar Baru Nilai, 71800 Nilai, Negeri
                    Sembilan Malaysia
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    https://klinikpakar.usim.edu.my
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    +60126504921
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    English, Malay
                  </SuiTypography>
                  <Link to="/makeappointment">
                    <SuiButton color="primary">Next Available: Tuesday, March 22</SuiButton>
                  </Link>
                </SuiBox>
              </SuiBox>
            </Link>
          </Card>
        </Grid>
      </Grid>
    ),
  },
];

function FindDoctor() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCmkgmny08KLFazm8YI30a7MwtrN_qLwi0",
  });

  const [currentTab, setCurrentTab] = useState("Map");

  return isLoaded ? (
    <DashboardLayout>
      <Header />
      <SuiBox py={7}>
        <SuiBox>
          <Tabs
            sx={{ width: 400 }}
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            color="primary"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                color="primary"
                disableRipple
                key={tab.value}
                label={tab.value}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 4 }} />

          <Grid container spacing={3}>
            <Grid item xs={12} sx={12} md={4} lg={3}>
              <Card>
                <Card color="primary">
                  <SuiBox padding={3} component="form" role="form">
                    <SuiBox mb={2}>
                      <SuiInput type="primarycare" placeholder="Primary Care" />
                    </SuiBox>
                    <SuiBox mb={2}>
                      <SuiInput type="zipcode" placeholder="Zip code or Neighborhood" />
                    </SuiBox>
                  </SuiBox>
                </Card>
                <Card sx={{ marginTop: 1 }}>
                  <SuiBox padding={3}>
                    <SuiBox mb={1}>
                      <SuiTypography variant="body2">Filter By</SuiTypography>
                    </SuiBox>
                    <SuiBox mb={1}>
                      <SuiSelect placeholder="Specialty" options={selectData.spec} />
                    </SuiBox>
                    <SuiBox mb={1}>
                      <SuiSelect placeholder="Gender" options={selectData.gender} />
                    </SuiBox>
                    <SuiBox mb={1}>
                      <SuiSelect placeholder="Condition" options={selectData.condition} />
                    </SuiBox>
                    <SuiBox mb={2}>
                      <SuiSelect placeholder="languages" options={selectData.lang} />
                    </SuiBox>
                    <SuiBox mb={1}>
                      <SuiTypography variant="body2">Providers Who Treat</SuiTypography>
                      <SuiBox paddingLeft={2}>
                        <FormControlLabel
                          sx={{ marginRight: 8 }}
                          control={<Checkbox />}
                          label="All Ages"
                          size="large"
                        />
                        <FormControlLabel
                          sx={{ marginRight: 8 }}
                          control={<Checkbox />}
                          label="Children"
                          size="large"
                        />
                        <FormControlLabel
                          sx={{ marginRight: 8 }}
                          control={<Checkbox />}
                          label="Adults"
                          size="large"
                        />
                      </SuiBox>
                    </SuiBox>
                    <SuiBox mb={1}>
                      <Divider />
                      <SuiTypography variant="body2">View Only</SuiTypography>
                      <SuiBox paddingLeft={2}>
                        <FormControlLabel
                          fontSize={2}
                          textAlign="left"
                          control={<Checkbox />}
                          label="Online Scheduling"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Primary Care"
                          size="large"
                        />
                      </SuiBox>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Card>
            </Grid>
            <Grid item xs={12} sx={8} md={8} lg={9}>
              <SuiBox display="flex" marginBottom={2}>
                <SuiBox marginRight={2}>
                  <SuiTypography>Sort By</SuiTypography>
                </SuiBox>
                <SuiBox marginRight={1}>
                  <SuiButton color="primary">Next Available</SuiButton>
                </SuiBox>

                <SuiButton color="primary">Distance</SuiButton>
              </SuiBox>
              <Card>
                {ACCOUNT_TABS.map((tab) => {
                  const isMatched = tab.value === currentTab;
                  return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
              </Card>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <p>N/A</p>
  );
}

export default FindDoctor;
