/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Settings page components
import Header from "layouts/pages/Patient/hospital/components/Header";
import Sidenav from "layouts/pages/Patient/hospital/components/Sidenav";

import BasicInfo from "layouts/pages/Patient/hospital/components/BasicInfo";
import ChangePassword from "layouts/pages/Patient/hospital/components/ChangePassword";
import Authentication from "layouts/pages/Patient/hospital/components/Authentication";
import Accounts from "layouts/pages/Patient/hospital/components/Accounts";
import Insurance from "layouts/pages/Patient/hospital/components/inshurance";

import Notifications from "layouts/pages/Patient/hospital/components/Notifications";
import Sessions from "layouts/pages/Patient/hospital/components/Sessions";
import DeleteAccount from "layouts/pages/Patient/hospital/components/DeleteAccount";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import FullBody from "./components/FullBody";

function Hospital() {
  return (
    <DashboardLayout>
      <SuiBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12}>
            <Header />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FullBody
              title="Top Ranking"
              discreption="Ranked among best hospital in UK"
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FullBody
              title="Excellent Services"
              discreption="InPatient and outpatient high quality services"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FullBody
              title="Experienced Doctors"
              discreption="High Quality healthcare by professional and experienced Doctors"
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
              </Grid>
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ChangePassword />
              </Grid>
              <Grid item xs={12}>
                <Authentication />
              </Grid>
              <Grid item xs={12}>
                <Accounts />
              </Grid>
              <Grid item xs={12}>
                <Notifications />
              </Grid>
              <Grid item xs={12}>
                <Sessions />
              </Grid>
              <Grid item xs={12}>
                <DeleteAccount />
              </Grid>
              <Grid item xs={12}>
                <Insurance />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Hospital;
