/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Images

function Accounts() {
  return (
    <Card id="accounts">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">Our Location</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          Klinik Pakar Kesihatan USIM
        </SuiTypography>
      </SuiBox>
      <Grid container paddingLeft={3} spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SuiBox display="flex" alignItems="center">
              <Icon fontSize="small">phone</Icon>
              <SuiBox ml={2}>
                <SuiTypography variant="button" color="text" fontWeight="medium">
                  +60126504921
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SuiBox mb={4} display="flex" alignItems="center">
              <Icon fontSize="small">add_location</Icon>
              <SuiBox ml={2}>
                <SuiTypography variant="button" color="text" fontWeight="medium">
                  {" "}
                  Lot 193,194 Jalan Nilai Square 6, Bandar Baru Nilai, 71800 Nilai, Negeri Sembilan
                  Malaysia
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} />
      </Grid>
    </Card>
  );
}

export default Accounts;
