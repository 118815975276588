/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function FullBody({ title, discreption }) {
  return (
    <Card sx={{ height: 150 }}>
      <SuiBox display="flex" pt={3} px={3}>
        <SuiTypography variant="h5" color="warning" textGradient fontWeight="bold">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiTypography variant="body2" color="text" fontWeight="regular">
          {discreption}
        </SuiTypography>
      </SuiBox>
    </Card>
  );
}
FullBody.propTypes = {
  title: PropTypes.string.isRequired,
  discreption: PropTypes.string.isRequired,
};
export default FullBody;
