/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React components
import SuiTypography from "components/SuiTypography";

const RequestAppoinment = [
  {
    color: "dark",
    icon: "devices_other",
    name: "Mustafa",
    description: (
      <SuiTypography variant="caption" color="text" fontWeight="medium">
        Orthopedic
      </SuiTypography>
    ),
    date: "17 May - 09:04",
    route: "/",
  },
  {
    color: "dark",
    icon: "settings",
    name: "KHIRULLAH",
    description: (
      <SuiTypography variant="caption" color="text" fontWeight="medium">
        Headache
      </SuiTypography>
    ),
    date: "20 May - 4:30",
    route: "/",
  },
  {
    color: "dark",
    icon: "info",
    name: "Bilal",
    description: (
      <SuiTypography variant="caption" color="text" fontWeight="medium">
        Protestat
      </SuiTypography>
    ),
    date: "14 Aug - 08:20",
    route: "/",
  },
];

export default RequestAppoinment;
