/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Footer from "examples/Footer";
import Header from "layouts/pages/Patient/searchDoctor/components/Header";
// Overview page components

// Data

import doctorsdata from "layouts/pages/Patient/searchDoctor/data/salesTableData2";
import newdoctorsdata from "layouts/pages/Patient/searchDoctor/data/newdoctors";

function SearchDoctor() {
  // DefaultStatisticsCard state for the dropdown value

  // DefaultStatisticsCard state for the dropdown action

  // DefaultStatisticsCard handler for the dropdown action

  // Dropdown menu template for the DefaultStatisticsCard

  return (
    <DashboardLayout>
      <Header />
      <SuiBox py={12}>
        <SuiBox mb={6}>
          <Card>
            <Typography marginTop={3} variant="h4" textAlign="center">
              Special Services
            </Typography>
            <SuiBox pt={2} px={4}>
              <SuiBox mb={4}>
                <Grid container spacing={3}>
                  {doctorsdata.map((data) => (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Link to="/finddoctor">
                        <Accordion style={{ marginBottom: 4 }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <SuiBox
                              component="img"
                              src={data.logo}
                              alt="message-image"
                              borderRadius="50%"
                              shadow="md"
                              width="15%"
                              backgroundColor="transparent"
                              height="10%"
                              marginRight={1}
                            />
                            <Grid>
                              <Typography>{data.title}</Typography>

                              <Typography variant="h6">{data.Discrepton}</Typography>
                            </Grid>
                          </AccordionSummary>
                        </Accordion>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </SuiBox>
            </SuiBox>
          </Card>
        </SuiBox>

        <Grid container spacing={3}>
          <Grid item lg={12}>
            <SuiBox mb={6}>
              <Card>
                <Typography marginTop={4} variant="h4" textAlign="center">
                  Find Doctors By Specialty
                </Typography>
                <Typography variant="subtitle" textAlign="center">
                  Select a Specialty to View all Doctors and schedule an Appointment
                </Typography>
                <SuiBox pt={2} px={4}>
                  <SuiBox mb={4}>
                    <Grid container spacing={3}>
                      {newdoctorsdata.map((data) => (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Link to="/finddoctor">
                            <Accordion style={{ marginBottom: 4 }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Grid>
                                  <Typography>{data.title}</Typography>
                                </Grid>
                              </AccordionSummary>
                            </Accordion>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </SuiBox>
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SearchDoctor;
