/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import SuiButton from "components/SuiButton";

function BasicInfo() {
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">Add Patient Symptoms</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Does patient have cold ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="me" control={<Radio />} label="Yes" />
                  <FormControlLabel value="dependent" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Days of being sick ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="1" control={<Radio />} label="1" />
                  <FormControlLabel value="2" control={<Radio />} label="2" />
                  <FormControlLabel value="3" control={<Radio />} label="3" />
                  <FormControlLabel value="4" control={<Radio />} label="4" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiTypography>Has patient taken any medicine ?</SuiTypography>
            <SuiBox marginLeft={2}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="me" control={<Radio />} label="Yes" />
                  <FormControlLabel value="dependent" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Enter Patient Blood Pressure" placeholder="180/120" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Enter Patient Blood Symptoms" placeholder="180/120" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="If Patient Taken Medicines, Name them "
              placeholder="Esomephrazole, Aspirine, Skilix"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Gender
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect placeholder="Male" options={selectData.gender} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Blood Group
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect placeholder="A+" options={selectData.blood} />
                </SuiBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <SuiButton variant="gradient" size="small" color="primary">
              Add
            </SuiButton>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default BasicInfo;
