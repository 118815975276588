/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useState } from "react";
import SuiButton from "components/SuiButton";

import FormField from "layouts/pages/account/components/FormField";

function Services() {
  const [showResults, setShowResults] = useState(false);
  const onClick = () => {
    setShowResults(!showResults);
  };

  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <SuiBox px={3} paddingTop={3} lineHeight={1} justifyContent="space-between" display="flex">
        <SuiBox mb={1}>
          <SuiTypography variant="h5"> Services</SuiTypography>
        </SuiBox>
        <SuiBox mb={1}>
          <SuiButton variant="gradient" onClick={onClick} color="primary">
            <Icon fontSize="large">add</Icon>
          </SuiButton>
        </SuiBox>
      </SuiBox>
      {showResults ? (
        <Grid container spacing={2} px={3} paddingBottom={2}>
          <Grid item lg={6} sm={6}>
            <FormField label="Title" placeholder="Enter Title" />
          </Grid>
          <Grid item lg={6} sm={6}>
            <FormField label="Details" placeholder="Enter Details" />
          </Grid>
        </Grid>
      ) : null}
      <Grid container>
        <Grid p={2} item lg={6}>
          <Card style={{ borderRadius: 10, borderColor: "lightblue", borderWidth: 1, padding: 10 }}>
            <SuiTypography variant="gradient" color="primary">
              Nice Work
            </SuiTypography>
            <SuiTypography variant="caption">Working with better condition</SuiTypography>
          </Card>
        </Grid>
        <Grid p={2} item lg={6}>
          <Card style={{ borderRadius: 10, borderColor: "lightblue", borderWidth: 1, padding: 10 }}>
            <SuiTypography variant="gradient" color="primary">
              Nice Work
            </SuiTypography>
            <SuiTypography variant="caption">Working with better condition</SuiTypography>
          </Card>
        </Grid>
        <Grid p={2} item lg={6}>
          <Card style={{ borderRadius: 10, borderColor: "lightblue", borderWidth: 1, padding: 10 }}>
            <SuiTypography variant="gradient" color="primary">
              Nice Work
            </SuiTypography>
            <SuiTypography variant="caption">Working with better condition</SuiTypography>
          </Card>
        </Grid>
        <Grid p={2} item lg={6}>
          <Card style={{ borderRadius: 10, borderColor: "lightblue", borderWidth: 1, padding: 10 }}>
            <SuiTypography variant="gradient" color="primary">
              Nice Work
            </SuiTypography>
            <SuiTypography variant="caption">Working with better condition</SuiTypography>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Services;
